import styled from "styled-components";
import empty200 from "../../../img/empty200.png";
// !Icons
import EyeIcon from "icons/EyeIcon";
import EditIcon from "../../../icons/EditIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { FaFolder } from "react-icons/fa";
import Pagination from "components_admin/atoms/Pagination";
import TableSt from "styles/components/templates/TableSt";
import { parseDate } from "libs/parseDate";
const TableGuestsSt = styled(TableSt)`
  .row {
    grid-template-columns: 6rem calc(50% - 11rem) 15% 15% 15% 5rem;
  }
  // !Estilos para DESKTOP
  @media only screen and (max-width: 568px) {
    .row {
      grid-template-columns: 6rem calc(50% - 9rem) 50% 3rem;
    }
  }
`;

interface props {
  messagesDocs: any;
  folderImg: string;
}
export default function TableGuests(props: props) {
  const navigate = useNavigate();
  return (
    <TableGuestsSt>
      <section className="row fixed">
        <div className="cell cell_header cell_center">{props.messagesDocs.count}</div>
        <div className="cell cell_header">Usuario</div>
        <div className="cell cell_header mobile_hidden cell_center">Dificultad</div>
        <div className="cell cell_header cell_center">Expira</div>
        <div className="cell cell_header mobile_hidden cell_center">Tiempo</div>
      </section>

      {props.messagesDocs.rows.map((i: any) => (
        <section className="row" key={i.id}>
          <img className="cell cell_thumbnail" src={empty200} alt="" />
          <div className="cell">{i.user_name}</div>
          <div className="cell mobile_hidden cell_center">{i.dificulty}</div>
          <div className="cell cell_center" style={i.expiration < Date.now() ? { color: "#ff004c" } : { color: "#00ff95" }}>
            {parseDate(i.expiration)}
          </div>
          <div className="cell mobile_hidden cell_center">{i.time}</div>

          {/* <EyeIcon className="icon" onClick={() => navigate(`update/${i.id}`)} />
          <EditIcon className="icon" onClick={() => navigate(`/admin/titles/update/${i.title_id}`)} /> */}
          <DeleteIcon className="icon" onClick={() => navigate(`delete/${i.id}`)} />
        </section>
      ))}
      {/* <Pagination count={props.moviesDocs.count} options={props.options} setOptions={props.setOptions} /> */}
    </TableGuestsSt>
  );
}
