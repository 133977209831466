export interface subscription {
  id: number;
  user_name: string;
  name: string;
  last_name: string;
  phone: string;
  email: string;
  starts: number;
  expiration: number;
  screens: number;
  userId: number;
  price: number;
  currency: string;
  plan: string;
}

export const templateSubscription: subscription = {
  id: 0,
  user_name: "",
  name: "",
  last_name: "",
  phone: "",
  email: "",
  starts: 0,
  expiration: 0,
  screens: 0,
  userId: 0,
  price: 0,
  currency: "",
  plan: "",
};
