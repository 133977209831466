import axios from "axios";
import { log } from "console";
import { templateEpisode } from "types/episode";
import { BlobImage } from "./BlobImage";

export const downloadEpisode = async (
  TV_ID: string | undefined,
  TMDB_ID: string | undefined,
  api_key: string,
  token: string,
  tmdb_uri_poster: string,
  setThumbnail: any,
  setState: any,
  SEASON_NUMBER: string | undefined,
  EPISODE_NUMBER: string | undefined
) => {
  let template = templateEpisode;
  await axios
    .get(
      `https://api.themoviedb.org/3/tv/${TMDB_ID}/season/${SEASON_NUMBER}/episode/${EPISODE_NUMBER}?api_key=${api_key}&language=es-MX`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (res) {
//       console.log(res.data);
      BlobImage(tmdb_uri_poster, res.data.still_path, setThumbnail);
      template = {
        ...template,
        titleId: parseInt(TV_ID || "1"),
        name: res.data.name,
        air_date: res.data.air_date,
        season_number: res.data.season_number,
        episode_number: res.data.episode_number,
        overview: res.data.overview,
        tmdb_id: res.data.id,
        runtime: res.data.runtime,
        vote_average: res.data.vote_average,
      };
      setState(template);
    })
    .catch(function (error) {
      console.log(error);
    });
  // !OTHER TITLES
  await axios
    .get(
      `https://api.themoviedb.org/3/tv/${TMDB_ID}/season/${SEASON_NUMBER}/episode/${EPISODE_NUMBER}/translations?api_key=${api_key}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (res) {
      //     console.log(res.data);
      const name_es = res.data.translations.find((i: any) => i.iso_639_1 === "es").data.name || "";
      const overview_es = res.data.translations.find((i: any) => i.iso_639_1 === "es").data.overview || "";

      template = {
        ...template,
        name_es: name_es,
        overview_es: overview_es,
      };
      setState(template);
    })
    .catch(function (error) {
      console.log(error);
    });
};
