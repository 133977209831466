import styled from "styled-components";
import ScrollSt from "./ScrollSt";

export const TableSt = styled(ScrollSt)`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: 4rem;
  overflow-y: scroll;
  .row {
    width: 100%;
    display: grid;
    /* grid-template-columns: 6rem calc(35% - 10rem) calc(35% - 11rem) 10% 10% 10% 5rem 5rem 5rem; */
    grid-template-rows: 100%;
    border-bottom: 1px solid #1e1e1e;
    justify-self: center;
    &:hover {
      background: #080808;
      .cell {
        color: white;
      }
      .icon {
        color: white;
      }
      .cell_thumbnail {
        z-index: 1;
        width: 100%;
        height: auto;
        justify-self: center;
        align-self: center;
        padding: 0;
      }
    }
    .cell {
      color: #848484;
      padding: 0 1vw;
      font-size: 1rem;
      line-height: 4rem;
      font-family: "Inter_500";
      // !Dots ...
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .cell_center {
      text-align: center;
    }
    .cell_header {
      color: #dadada;
      font-family: "Inter_700";
    }
    /* .cell_warning {
      color: #ff0044;
    }
    .cell_caution {
      color: #ffd900;
    }
    .cell_success {
      color: #00ffb7;
    } */
    .cell_thumbnail {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .mobile_hidden {
      /* display: flex; */
    }
    .icon {
      width: 100%;
      height: 100%;
      justify-self: center;
      align-self: center;
      padding: 1.3rem;
      color: #848484;
      cursor: pointer;
    }
  }
  .fixed {
    position: sticky;
    top: 0;
    background: #000000;
  }
  .api {
    background: rgb(186, 0, 0);
    background: linear-gradient(0deg, rgba(186, 0, 0, 1) 0%, rgba(255, 0, 0, 1) 100%);
  }
  @media only screen and (max-width: 568px) {
    .row {
      width: 100%;
      display: grid;
      /* grid-template-columns: 6rem calc(100% - 15rem) 3rem 3rem 3rem; */
      .mobile_hidden {
        display: none;
      }
      .icon {
        padding: 0.8rem;
      }
    }
  }
`;
export default TableSt;
