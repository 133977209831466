import { useAppSelector } from "../../../app/hooks";
import Spinner from "../../atoms/Spinner";
import useCrudTv from "../../../hooks/useCrudTv";
import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import TemplateTitle from "./TemplateTitle";
import useTitle from "./useTitle";

import { downloadTv } from "libs/downloadTv";

export default function DOWNLOAD_TITLE_TV() {
  const params = useParams();
  // !Context OUTLET
  const [URI] = useOutletContext<any>();
  const {
    state,
    setState,
    handleChange,
    formData,
    backdrop,
    setBackdrop,
    backdropPortrait,
    setBackdropPortrait,
    poster,
    setPoster,
    posterLandscape,
    setPosterLandscape,
    logo,
    setLogo,
  } = useTitle();
  const { DOWNLOAD_POST_FORM_DATA, uploading } = useCrudTv();

  // !Handle SUBMIT POST
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    DOWNLOAD_POST_FORM_DATA(URI, formData);
  };

  // !Params TMDB
  const tv_id = params.id || "";
  //   const api_key = "eafee52978a6fa33af7118d81011116b";
  const api_key = "50bee1ebe32fd4c7280a08d1dafc1a84";
  const token =
    "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJlYWZlZTUyOTc4YTZmYTMzYWY3MTE4ZDgxMDExMTE2YiIsInN1YiI6IjYzMjY4MzdiNjdiNjEzMDA3Yjg5MzA1MSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.DkYIhC6xJOI2qoaHyD1OVOXVBcZRKaeDXm6In9d0srM";
  const tmdb_uri_img_blob = "https://image.tmdb.org/t/p/original";

  useEffect(() => {
    downloadTv(
      tv_id,
      api_key,
      token,
      tmdb_uri_img_blob,
      setBackdrop,
      setBackdropPortrait,
      setPoster,
      setPosterLandscape,
      setState,
      setLogo
    );
  }, []);

  // !uploading data
  if (uploading) {
    return <Spinner position="absolute" />;
  }
  return (
    <TemplateTitle
      titleText="Crear serie usando API"
      buttonText="Crear serie usando API"
      titleImg="Imagen"
      state={state}
      setState={setState}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      readMode={false}
      backdrop={backdrop}
      setBackdrop={setBackdrop}
      backdropPortrait={backdropPortrait}
      setBackdropPortrait={setBackdropPortrait}
      poster={poster}
      setPoster={setPoster}
      posterLandscape={posterLandscape}
      setPosterLandscape={setPosterLandscape}
      logo={logo}
      setLogo={setLogo}
      download_from_api={true}
      update_images={true}
      show_update_button={false}

    />
  );
}
