import { useAppSelector } from "../app/hooks";
import axios from "axios";
import { selectLogin } from "../features/login/loginSlice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import headers from "libs/headers";
import { BlobImage } from "../libs/BlobImage";
import toast from "react-hot-toast";
import { templateTitle } from "types/title";

export default function useCrudMovie() {
  const navigate = useNavigate();

  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const login = useAppSelector(selectLogin);
  const api_key = "eafee52978a6fa33af7118d81011116b";
  const token =
    "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJlYWZlZTUyOTc4YTZmYTMzYWY3MTE4ZDgxMDExMTE2YiIsInN1YiI6IjYzMjY4MzdiNjdiNjEzMDA3Yjg5MzA1MSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.DkYIhC6xJOI2qoaHyD1OVOXVBcZRKaeDXm6In9d0srM";
  // !Handle DOWNLOAD_POST_FORM_DATA
  const DOWNLOAD_POST_FORM_DATA = async (uri: string, formData: object) => {
    setUploading(true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/${uri}`, formData, headers(login.token))
      .then(function (res) {
        setUploading(false);
        toast.success(res.data.message);
        console.log(res.data.message);
        navigate(-1);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };

  // !Handle POST_FORM_DATA
  const POST_FORM_DATA = async (uri: string, formData: object, reloadBack: () => {}) => {
    setUploading(true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/${uri}`, formData, headers(login.token))
      .then(function (res) {
        setUploading(false);
        reloadBack();
        toast.success(res.data.message);
        console.log(res.data.message);
        navigate(-1);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };

  // !Handle GET
  const GET = async (
    uri: string,
    id: string | null,
    setState: any,
    setBackdrop: any,
    setBackdropPortrait: any,
    setPoster: any,
    setPosterLandscape: any,
    setLogo: any
  ) => {
    let template = templateTitle;
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/${uri}/${id}/1`, headers(login.token)) //TODO organizar rutas backend
      .then(function (res) {
        template = res.data;
        // console.log(res.data);

        BlobImage(`${process.env.REACT_APP_STATIC_BUCKET}/backdrops/w1920`, res.data.backdrop_path, setBackdrop);
        BlobImage(
          `${process.env.REACT_APP_STATIC_BUCKET}/backdrops_portrait/w1000`,
          res.data.backdrop_portrait_path,
          setBackdropPortrait
        );
        BlobImage(`${process.env.REACT_APP_STATIC_BUCKET}/posters/w350`, res.data.poster_path, setPoster);
        BlobImage(
          `${process.env.REACT_APP_STATIC_BUCKET}/posters_landscape/w450`,
          res.data.poster_landscape_path,
          setPosterLandscape
        );
        BlobImage(`${process.env.REACT_APP_STATIC_BUCKET}/logos/w600`, res.data.logo_path, setLogo);
      })
      .catch(function (error) {
        setDownloading(false);
        console.log(error);
      });
    // !GET IMAGES
    await axios
      .get(`https://api.themoviedb.org/3/${template.type}/${template.tmdb_id}/images?api_key=${api_key}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then(function (res) {
        if (res.status === 200) {
          // !Backdrops
          let backdrops: any = [];
          backdrops = [...backdrops, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === null)];
          backdrops = [...backdrops, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "es")];
          backdrops = [...backdrops, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "en")];
          if (template.original_language !== "en" && template.original_language !== "en") {
            backdrops = [
              ...backdrops,
              ...res.data.backdrops.filter((i: any) => i.iso_639_1 === template.original_language),
            ];
          }
          // !Backdrops PORTRAIT
          let backdrops_portrait: any = [];
          backdrops_portrait = [...backdrops_portrait, ...res.data.posters.filter((i: any) => i.iso_639_1 === null)];
          backdrops_portrait = [...backdrops_portrait, ...res.data.posters.filter((i: any) => i.iso_639_1 === "es")];
          backdrops_portrait = [...backdrops_portrait, ...res.data.posters.filter((i: any) => i.iso_639_1 === "en")];
          if (template.original_language !== "en" && template.original_language !== "en") {
            backdrops_portrait = [
              ...backdrops_portrait,
              ...res.data.posters.filter((i: any) => i.iso_639_1 === template.original_language),
            ];
          }

          // !Logos
          let logos: any = [];
          logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === "es")];
          logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === "en")];
          logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === null)];
          if (template.original_language !== "en" && template.original_language !== "en") {
            logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === template.original_language)];
          }

          // !Posters
          let posters: any = [];
          posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === "es")];
          posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === "en")];
          if (template.original_language !== "en" && template.original_language !== "en") {
            posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === template.original_language)];
          }
          posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === null)];

          template = {
            ...template,
            backdrops: backdrops,
            backdrops_portrait: backdrops_portrait,
            posters: posters,
            logos: logos,
          };
          // !Posters Landscape
          let posters_landscape: any = [];
          posters_landscape = [...posters_landscape, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "es")];
          posters_landscape = [...posters_landscape, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "en")];
          if (template.original_language !== "en" && template.original_language !== "en") {
            posters_landscape = [
              ...posters_landscape,
              ...res.data.backdrops.filter((i: any) => i.iso_639_1 === template.original_language),
            ];
          }
          posters_landscape = [...posters_landscape, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === null)];

          template = {
            ...template,
            backdrops: backdrops,
            backdrops_portrait: backdrops_portrait,
            posters: posters,
            logos: logos,
            posters_landscape: posters_landscape,
          };
          setState(template);
          setDownloading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setState(template);
        toast.error("tmdb_id invalido!");
      });
  };

  // !Handle PUT_FORM_DATA
  const PUT_FORM_DATA = async (uri: string, id: string, data: object, reloadBack: () => {}) => {
    setUploading(true);
    await axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/${uri}/${id}`, data, headers(login.token))
      .then(function (res) {
        setUploading(false);
        reloadBack();
        toast.success(res.data.message);
        console.log(res.data.message);
        navigate(-1);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };
  // !Handle DELETE
  const DELETE = async (uri: string, id: string, reloadBack: () => {}) => {
    setUploading(true);
    await axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/${uri}/${id}`, headers(login.token))
      .then((res) => {
        // setUploading(false);
        reloadBack();
        navigate(-1);
        toast.success(res.data?.message || "Borrado");
        // console.log(res);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };

  //   // !Fetching all docs
  //   const GET_ALL_MOVIES = async (uri: string, movies: any, setMovies: any, page: string) => {
  //     setDownloading(true);
  //     await axios
  //       .get(`${process.env.REACT_APP_BACKEND_URL}/${uri}?page=${page}`, headers(login.token))
  //       .then(function (res) {
  //         console.log(res.data);

  //         setDownloading(false);
  //         setMovies([...movies, ...res.data.rows]);
  //       })
  //       .catch(function (error) {
  //         console.log(error, "aaaaaa");

  //         checkErrors(error);
  //       });
  //   };
  return {
    DOWNLOAD_POST_FORM_DATA,
    POST_FORM_DATA,
    GET,
    PUT_FORM_DATA,
    DELETE,
    //     GET_ALL_MOVIES,
    downloading,
    uploading,
  };
}
