import ImagePreview from "../ImagePreview";
import InputForm from "components_admin/molecules/inputs/InputForm";
import TextareaForm from "components_admin/molecules/inputs/TextareaForm";
import SelectForm from "components_admin/molecules/inputs/SelectForm";
import folders from "json/folders.json";
import FormSt from "styles/components/templates/FormSt";
import ScrollSt from "styles/components/templates/ScrollSt";
import styled from "styled-components";
import InputDateForm from "components_admin/molecules/inputs/ImputDateForm";
import { log } from "console";
import { parseDate } from "libs/parseDate";
import ImagesPreview from "../ImagesPreview";
import ImagePreviewLink from "../ImagePreviewLink";
import { updateMovie } from "libs/updateMovie";
import { useState } from "react";
import { updateTv } from "libs/updateTv";
import Spinner from "components_admin/atoms/Spinner";
const ContainerFormSt = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  padding: 2rem 0;
  background: black;
  @media only screen and (max-width: 568px) {
    padding: 0rem 0rem;
  }
`;
interface props {
  titleText: string;
  buttonText: string;
  titleImg: string;
  state: any;
  setState: any;
  handleChange: any;
  handleSubmit: any;
  readMode: boolean;
  backdrop: any;
  setBackdrop: any;
  backdropPortrait: any;
  setBackdropPortrait: any;
  poster: any;
  setPoster: any;
  posterLandscape: any;
  setPosterLandscape: any;
  logo: any;
  setLogo: any;
  download_from_api: boolean;
  update_images: boolean;
  show_update_button: boolean;
}
export default function TemplateAdvance(props: props) {
  const tmdb_uri_img_blob = "https://image.tmdb.org/t/p/original";
  const api_key = "50bee1ebe32fd4c7280a08d1dafc1a84";
  const token =
    "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJlYWZlZTUyOTc4YTZmYTMzYWY3MTE4ZDgxMDExMTE2YiIsInN1YiI6IjYzMjY4MzdiNjdiNjEzMDA3Yjg5MzA1MSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.DkYIhC6xJOI2qoaHyD1OVOXVBcZRKaeDXm6In9d0srM";
  const [spinner, setSpinner] = useState(false);
  console.log(props.state);

  if (spinner) {
    return <Spinner position="absolute" />;
  }
  return (
    <ContainerFormSt>
      <FormSt onSubmit={(e) => props.handleSubmit(e)}>
        {props.show_update_button && (
          <>
            <InputForm
              label="tmdb_id"
              type="text"
              name="tmdb_id"
              value={props.state.tmdb_id}
              onChange={props.handleChange}
              required={false}
              readOnly={false}
            />

            {props.state.tmdb_id !== "" && props.state.tmdb_id !== 0 ? (
              <button
                className="form_button button_green"
                type="button"
                onClick={() =>
                  props.state.type === "movie"
                    ? updateMovie(props.state.tmdb_id, api_key, token, props.state, props.setState, setSpinner)
                    : updateTv(props.state.tmdb_id, api_key, token, props.state, props.setState, setSpinner)
                }
                disabled={spinner}
                style={spinner ? { background: "red" } : {}}
              >
                Actualizar
              </button>
            ) : null}
            <button
              className="form_button"
              type="submit"
              //     style={!props.backdrop || !props.poster ? { background: "red" } : { background: "#6200ff" }}
            >
              {props.buttonText}
            </button>
            <TextareaForm
              label="Sinopsis ESPAÑA"
              name="overview_es"
              value={`
              backdrops_portrait: ${props.state.data_game?.backdrops_portrait?.length}
              similar_titles_en: ${props.state.data_game?.similar_titles_en?.length}
              similar_titles_es: ${props.state.data_game?.similar_titles_es?.length}
              `}
              onChange={() => {}}
              required={false}
            />
          </>
        )}
        <p className="form_title">{props.titleText}</p>
        <div className="form_line"></div>
        {/* {props.update_images === true && (
          <>
            <ImagesPreview
              title="Fondo computadora"
              images={props.state.backdrops}
              thumbnail_tmdb_uri="https://www.themoviedb.org/t/p/w500_and_h282_face"
              file={props.backdrop}
              setFile={props.setBackdrop}
              min_size={1080}
              blob_tmdb_uri={tmdb_uri_img_blob}
            />
            <ImagesPreview
              title="Fondo celular"
              images={props.state.backdrops_portrait}
              thumbnail_tmdb_uri="https://www.themoviedb.org/t/p/w220_and_h330_face"
              file={props.backdropPortrait}
              setFile={props.setBackdropPortrait}
              min_size={1080}
              blob_tmdb_uri={tmdb_uri_img_blob}
            />
            <ImagesPreview
              title="logo"
              images={props.state.logos}
              thumbnail_tmdb_uri="https://www.themoviedb.org/t/p/w500"
              file={props.logo}
              setFile={props.setLogo}
              min_size={200}
              blob_tmdb_uri={tmdb_uri_img_blob}
            />
            <ImagesPreview
              title="Poster vertical"
              images={props.state.posters}
              thumbnail_tmdb_uri="https://www.themoviedb.org/t/p/w220_and_h330_face"
              file={props.poster}
              setFile={props.setPoster}
              min_size={600}
              blob_tmdb_uri={tmdb_uri_img_blob}
            />
            <ImagesPreview
              title="Poster horizontal"
              images={props.state.posters_landscape}
              thumbnail_tmdb_uri="https://www.themoviedb.org/t/p/w500_and_h282_face"
              file={props.posterLandscape}
              setFile={props.setPosterLandscape}
              min_size={150}
              blob_tmdb_uri={tmdb_uri_img_blob}
            />
          </>
        )} */}
        {/* {props.download_from_api === false && (
          <>
            <ImagePreview file={props.backdrop} setFile={props.setBackdrop} />
            <ImagePreview file={props.backdropPortrait} setFile={props.setBackdropPortrait} />
            <ImagePreview file={props.logo} setFile={props.setLogo} />
            <ImagePreview file={props.poster} setFile={props.setPoster} />
            <ImagePreview file={props.posterLandscape} setFile={props.setPosterLandscape} />
          </>
        )} */}

        {props.update_images !== true ? (
          <>
            <ImagePreviewLink file={props.backdrop} setFile={props.setBackdrop} title="Fondo computadora" />
            <ImagePreviewLink file={props.backdropPortrait} setFile={props.setBackdropPortrait} title="Fondo celular" />
            <ImagePreviewLink file={props.logo} setFile={props.setLogo} title="logo" />
            <ImagePreviewLink file={props.poster} setFile={props.setPoster} title="Poster vertical" />
            <ImagePreviewLink
              file={props.posterLandscape}
              setFile={props.setPosterLandscape}
              title="Poster horizontal"
            />
          </>
        ) : (
          <>
            <ImagesPreview
              title="Fondo computadora"
              images={props.state.backdrops}
              thumbnail_tmdb_uri="https://www.themoviedb.org/t/p/w500_and_h282_face"
              file={props.backdrop}
              setFile={props.setBackdrop}
              min_size={1080}
              blob_tmdb_uri={tmdb_uri_img_blob}
            />
            <ImagesPreview
              title="Fondo celular"
              images={props.state.backdrops_portrait}
              thumbnail_tmdb_uri="https://www.themoviedb.org/t/p/w220_and_h330_face"
              file={props.backdropPortrait}
              setFile={props.setBackdropPortrait}
              min_size={1080}
              blob_tmdb_uri={tmdb_uri_img_blob}
            />
            <ImagesPreview
              title="logo"
              images={props.state.logos}
              thumbnail_tmdb_uri="https://www.themoviedb.org/t/p/w500"
              file={props.logo}
              setFile={props.setLogo}
              min_size={200}
              blob_tmdb_uri={tmdb_uri_img_blob}
            />
            <ImagesPreview
              title="Poster vertical"
              images={props.state.posters}
              thumbnail_tmdb_uri="https://www.themoviedb.org/t/p/w220_and_h330_face"
              file={props.poster}
              setFile={props.setPoster}
              min_size={600}
              blob_tmdb_uri={tmdb_uri_img_blob}
            />
            <ImagesPreview
              title="Poster horizontal"
              images={props.state.posters_landscape}
              thumbnail_tmdb_uri="https://www.themoviedb.org/t/p/w500_and_h282_face"
              file={props.posterLandscape}
              setFile={props.setPosterLandscape}
              min_size={150}
              blob_tmdb_uri={tmdb_uri_img_blob}
            />
          </>
        )}
        <InputForm
          label="ID de coleción"
          type="number"
          name="collection_id"
          value={props.state.collection_id}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Título"
          type="text"
          name="title"
          value={props.state.title}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Título ESPAÑA"
          type="text"
          name="title_es"
          value={props.state.title_es}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Título original"
          type="text"
          name="original_title"
          value={props.state.original_title}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Idioma original"
          type="text"
          name="original_language"
          value={props.state.original_language}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Tagline"
          type="text"
          name="tagline"
          value={props.state.tagline}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Tagline ESPAÑA"
          type="text"
          name="tagline_es"
          value={props.state.tagline_es}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Fecha de estreno"
          type="text"
          name="date"
          value={props.state.date}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Duración"
          type="number"
          name="runtime"
          value={props.state.runtime}
          onChange={props.handleChange}
          required={true}
          readOnly={false}
        />
        <InputForm
          label="Calificación"
          type="number"
          name="vote_average"
          value={props.state.vote_average}
          onChange={props.handleChange}
          required={true}
          readOnly={false}
        />
        <InputForm
          label="Productoras"
          type="text"
          name="production_companies"
          value={props.state.production_companies}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Paises"
          type="text"
          name="production_countries"
          value={props.state.production_countries}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <TextareaForm
          label="Sinopsis"
          name="overview"
          value={props.state.overview}
          onChange={props.handleChange}
          required={false}
        />
        <TextareaForm
          label="Sinopsis ESPAÑA"
          name="overview_es"
          value={props.state.overview_es}
          onChange={props.handleChange}
          required={false}
        />
        <InputForm
          label="Presupuesto"
          type="number"
          name="budget"
          value={props.state.budget}
          onChange={props.handleChange}
          required={true}
          readOnly={false}
        />
        <InputForm
          label="Ganancias"
          type="number"
          name="revenue"
          value={props.state.revenue}
          onChange={props.handleChange}
          required={true}
          readOnly={false}
        />
        {/* // !credits fetch */}
        <InputForm
          label="Cast"
          type="text"
          name="cast"
          value={props.state.cast}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Director"
          type="text"
          name="director"
          value={props.state.director}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        {/* // !keywords fetch */}
        <InputForm
          label="keywords"
          type="text"
          name="keywords"
          value={props.state.keywords}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <SelectForm
          label="Certificado"
          name="certification"
          value={props.state.certification}
          onChange={props.handleChange}
          options={[
            { value: "", name: "none" },
            { value: "G", name: "G" },
            { value: "PG", name: "PG" },
            { value: "PG-13", name: "PG-13" },
            { value: "R", name: "R" },
            { value: "NC-17", name: "NC-17" },
            { value: "NR", name: "NR" },
          ]}
          required={true}
        />
        <InputForm
          label="Géneros"
          type="text"
          name="genres"
          value={props.state.genres}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        {/* // !Admin */}
        <SelectForm
          label="Carpeta"
          name="folder"
          value={props.state.folder}
          onChange={props.handleChange}
          options={folders}
          required={true}
        />
        <InputDateForm
          label={`Última actualización ${parseDate(props.state.last_update)} - HORA LOCAL`}
          type="datetime-local"
          name="last_update"
          value={props.state.last_update}
          onChange={props.handleChange}
        />
        {/* <div className="form_success">HORA LOCAL {parseDate(props.state.last_update)}</div> */}
        <InputForm
          label="Palabras clave"
          type="text"
          name="keywords_2"
          value={props.state.keywords_2}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        {props.state.type === "movie" && (
          <>
            <InputForm
              label="Link"
              type="text"
              name="link"
              value={props.state.link}
              onChange={props.handleChange}
              required={false}
              readOnly={false}
            />
            <SelectForm
              label="Resolución"
              name="resolution"
              value={props.state.resolution}
              onChange={props.handleChange}
              options={[
                { value: "1080", name: "1080" },
                { value: "1000", name: "Mejorado" },
                { value: "720", name: "720" },
                { value: "480", name: "480" },
              ]}
              required={true}
            />
          </>
        )}
        <SelectForm
          label="Audio"
          name="audio"
          value={props.state.audio}
          onChange={props.handleChange}
          options={[
            { value: "multilenguaje", name: "Multilenguaje" },
            { value: "latino", name: "Latino" },
            { value: "castellano", name: "Castellano" },
            { value: "subtitulado", name: "Subtitulado" },
          ]}
          required={true}
        />
        <SelectForm
          label="Disponible"
          name="available"
          value={props.state.available}
          onChange={props.handleChange}
          options={[
            { value: "false", name: "No" },
            { value: "true", name: "Si" },
          ]}
          required={true}
        />

        <button
          className="form_button"
          type="submit"
          //     style={!props.backdrop || !props.poster ? { background: "red" } : { background: "#6200ff" }}
        >
          {props.buttonText}
        </button>
      </FormSt>
    </ContainerFormSt>
  );
}
