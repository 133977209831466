import { useAppSelector } from "app/hooks";
import Spinner from "components_admin/atoms/Spinner";
import useCrudMovie from "hooks/useCrudMovie";
import React from "react";
import { useOutletContext } from "react-router-dom";
import TemplateSubscription from "./TemplateSubscription";
import useSubscription from "./useSubscription";

export default function POST_SUBSCRIPTION() {
  // !Context OUTLET
  const [URI, fetchEmployees] = useOutletContext<any>();
  const { state, setState, handleChange, formData, backdrop, setBackdrop, poster, setPoster } = useSubscription();
  const { POST_FORM_DATA, uploading } = useCrudMovie();

  // !Handle SUBMIT POST
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    POST_FORM_DATA(URI, formData, fetchEmployees);
  };

  // !uploading data
  if (uploading) {
    return <Spinner position="absolute" />;
  }

  return (
    <TemplateSubscription
      titleText="Crear título"
      buttonText="Crear título"
      titleImg="Imagen"
      state={state}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      readMode={false}
      backdrop={backdrop}
      setBackdrop={setBackdrop}
      poster={poster}
      setPoster={setPoster}
    />
  );
}
