import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import FormSt from "../../../styles/FormSt";
import { ModalSt } from "../../../styles/ModalSt";
import useCode from "./useCode";

const DELETE_CODE = () => {
  const navigate = useNavigate();
  const { DELETE } = useCode();
  const [GET_ALL_BACK] = useOutletContext<any>();
  // !Seting tvId to State
  useEffect(() => {
    return () => GET_ALL_BACK();
  }, []);
  return (
    <ModalSt>
      <FormSt onSubmit={DELETE}>
        <p className="form_title">Eliminar código</p>
        <p className="form_sub_title">Esto se eliminará definitivamente, no puedes deshacer esta acción.</p>

        <div className="form_line"></div>
        <button className="form_button button_white" type="submit">
          Eliminar
        </button>
        <button className="form_button button_gray" type="button" onClick={() => navigate(-1)}>
          Volver atras
        </button>
      </FormSt>
    </ModalSt>
  );
};

export default DELETE_CODE;
