import ImagePreview from "../ImagePreview";
import InputForm from "components_admin/molecules/inputs/InputForm";
import TextareaForm from "components_admin/molecules/inputs/TextareaForm";
import SelectForm from "components_admin/molecules/inputs/SelectForm";
import folders from "json/folders.json";
import FormSt from "styles/components/templates/FormSt";
import ScrollSt from "styles/components/templates/ScrollSt";
import styled from "styled-components";
import InputDateForm from "components_admin/molecules/inputs/ImputDateForm";
import { parseDate } from "libs/parseDate";

const ContainerFormSt = styled(ScrollSt)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  padding: 2rem 0;
  background: black;
  @media only screen and (max-width: 568px) {
    padding: 0rem 0rem;
  }
`;
interface props {
  titleText: string;
  buttonText: string;
  titleImg: string;
  state: any;
  handleChange: any;
  handleSubmit: any;
  readMode: boolean;
  backdrop: any;
  setBackdrop: any;
  poster: any;
  setPoster: any;
}
export default function TemplateAdvance(props: props) {
  return (
    <ContainerFormSt>
      <FormSt onSubmit={(e) => props.handleSubmit(e)}>
        <p className="form_title">{props.titleText}</p>
        <div className="form_line"></div>
        {/* <ImagePreview file={props.backdrop} setFile={props.setBackdrop} />
        <ImagePreview file={props.poster} setFile={props.setPoster} /> */}
        <InputForm
          label="Nombre de usuario"
          type="text"
          name="user_name"
          value={props.state.user_name}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />

        <InputForm
          label="Nombre"
          type="text"
          name="name"
          value={props.state.name}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Apellido"
          type="text"
          name="last_name"
          value={props.state.last_name}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Celular"
          type="text"
          name="phone"
          value={props.state.phone}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Correo electronico"
          type="text"
          name="email"
          value={props.state.email}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputDateForm
          label={`Empieza ${parseDate(props.state.starts)} - HORA LOCAL`}
          type="datetime-local"
          name="starts"
          value={props.state.starts}
          onChange={props.handleChange}
          //   required={false}
        />
        {/* <div className="form_success">HORA LOCAL {parseDate(props.state.starts)}</div> */}
        <InputDateForm
          label={`Expira ${parseDate(props.state.expiration)} - HORA LOCAL`}
          type="datetime-local"
          name="expiration"
          value={props.state.expiration}
          onChange={props.handleChange}
          //   required={false}
        />
        {/* <div className="form_success">HORA LOCAL {parseDate(props.state.expiration)}</div> */}

        <SelectForm
          label="Plan"
          name="plan"
          value={props.state.plan}
          onChange={props.handleChange}
          options={[
            { value: "basic", name: "Basic" },
            { value: "standard", name: "Standard" },
            { value: "premium", name: "Premium" },
          ]}
          required={true}
        />
        <InputForm
          label="Pantallas"
          type="text"
          name="screens"
          value={props.state.screens}
          onChange={props.handleChange}
          required={false}
          readOnly={true}
        />
        <button className="form_button" type="submit">
          {props.buttonText}
        </button>
      </FormSt>
    </ContainerFormSt>
  );
}
