import axios from "axios";
import { templateTitle } from "types/title";
import { BlobImage } from "./BlobImage";
import { tvCertificationToMovieCertificacion } from "./tvCertificationToMovieCertificacion";
import { v4 as uuidv4 } from "uuid";
export const updateTv = async (
  tv_id: string,
  api_key: string,
  token: string,
  state: any,
  setState: any,
  setSpinner: any
) => {
  setSpinner(true);
  let template = state;

  await axios
    .get(`https://api.themoviedb.org/3/tv/${tv_id}?api_key=${api_key}&language=es-MX`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      //       console.log(res.data);
      //       BlobImage(tmdb_uri_backdrop, res.data.backdrop_path, setBackdrop);
      //       BlobImage(tmdb_uri_poster, res.data.poster_path, setPoster);
      template = {
        ...template,
        title: res.data.name,
        original_title: res.data.original_name,
        original_language: res.data.original_language,
        last_update: new Date(res.data.first_air_date).getTime(),
        date: res.data.first_air_date,
        runtime: res.data.episode_run_time[0] || 0, // TODO
        vote_average: res.data.vote_average,
        genres: res.data.genres.map((i: any) => i.name).join(", "),
        production_companies: res.data.production_companies.map((i: any) => i.name).join(", "),
        production_countries: res.data.production_countries.map((i: any) => i.name).join(", "),
        tagline: res.data.tagline,
        overview: res.data.overview,
        tmdb_id: res.data.id,
        folder: "msc-series-tv",
        type: "tv",
      };
      //       console.log(res.data);
      //       setState(template);
    })
    .catch(function (error) {
      console.log(error);
    });
  // !GET TITLE ENGLISH
  await axios
    .get(`https://api.themoviedb.org/3/tv/${tv_id}?api_key=${api_key}&language=en-US`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      template = {
        ...template,
        title_en: res.data.name,
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  // !Credits for CAST & DIRECTOR
  await axios
    .get(`https://api.themoviedb.org/3/tv/${tv_id}/credits?api_key=${api_key}&language=es-MX`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      //       console.log(res.data);
      template = {
        ...template,
        cast: res.data.cast.map((i: any) => i.name).join(", "),
        director:
          res.data.crew.find(({ known_for_department }: any) => known_for_department.toLowerCase() === "directing")
            ?.name || "",
      };
      //       console.log(res);

      //       setState(template);
    })
    .catch(function (error) {
      console.log(error);
    });
  // !KEYWORDS
  await axios
    .get(`https://api.themoviedb.org/3/tv/${tv_id}/keywords?api_key=${api_key}&language=es-MX`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      template = {
        ...template,
        keywords: res.data.results.map((i: any) => i.name).join(", "),
      };
      //       console.log(res.data);
      //       setState(template);
    })
    .catch(function (error) {
      console.log(error);
    });
  // !Get ratings
  await axios
    .get(`https://api.themoviedb.org/3/tv/${tv_id}/content_ratings?api_key=${api_key}&language=en-US`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      const us = res.data.results.find((i: any) => i.iso_3166_1.toLowerCase() === "us"); // return value OR undefined
      const rating = us === undefined ? undefined : us.rating;
      const certification = rating === undefined ? "NR" : rating;
      template = {
        ...template,
        certification: tvCertificationToMovieCertificacion(certification),
      };
      setState(template);
    })
    .catch(function (error) {
      console.log(error);
    });
  // !OTHER TITLES
  await axios
    .get(`https://api.themoviedb.org/3/tv/${tv_id}/translations?api_key=${api_key}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      //       console.log(res.data);

      const title_es = res.data.translations.find((i: any) => i.iso_639_1 === "es").data.name || "";
      const tagline_es = res.data.translations.find((i: any) => i.iso_639_1 === "es").data.tagline || "";
      const overview_es = res.data.translations.find((i: any) => i.iso_639_1 === "es").data.overview || "";

      template = {
        ...template,
        title_es: title_es,
        tagline_es: tagline_es,
        overview_es: overview_es,
      };
      setState(template);
    })
    .catch(function (error) {
      console.log(error);
    });
  // !ALTERNATIVE TITLES ---> data_games
  //   await axios
  //     .get(`https://api.themoviedb.org/3/tv/${tv_id}/alternative_titles?api_key=${api_key}&language=en-US`, {
  //       headers: {
  //         authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then(function (res) {
  //       template = {
  //         ...template,
  //         data_game: { ...template.data_game, alternative_titles: res.data.results },
  //       };
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // !SIMILAR_TITLES  es-MX ---> data_games
  await axios
    .get(`https://api.themoviedb.org/3/tv/${tv_id}/similar?api_key=${api_key}&language=es-MX&page=1`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      const similar_titles_es: any = [];
      res.data.results.map((i: any) => {
        if (i.overview !== "") {
          similar_titles_es.push({ id: i.id, title: i.name });
        }
      });
      template = {
        ...template,
        data_game: { ...template.data_game, similar_titles_es: similar_titles_es },
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  // !SIMILAR_TITLES  en-US ---> data_games
  await axios
    .get(`https://api.themoviedb.org/3/tv/${tv_id}/similar?api_key=${api_key}&language=en-US&page=1`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      const similar_titles_en: any = [];
      res.data.results.map((i: any) => {
        if (i.overview !== "") {
          similar_titles_en.push({ id: i.id, title: i.name });
        }
      });
      template = {
        ...template,
        data_game: { ...template.data_game, similar_titles_en: similar_titles_en },
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  // !IMDB_ID
  await axios
    .get(`https://api.themoviedb.org/3/tv/${tv_id}/external_ids?api_key=${api_key}&language=en-US`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      template = {
        ...template,
        imdb_id: res.data.imdb_id || uuidv4(),
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  // !GET IMAGES
  await axios
    .get(`https://api.themoviedb.org/3/tv/${tv_id}/images?api_key=${api_key}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      // !Backdrops
      let backdrops: any = [];
      backdrops = [...backdrops, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === null)];
      backdrops = [...backdrops, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "es")];
      backdrops = [...backdrops, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "en")];
      if (template.original_language !== "en" && template.original_language !== "en") {
        backdrops = [
          ...backdrops,
          ...res.data.backdrops.filter((i: any) => i.iso_639_1 === template.original_language),
        ];
      }
      // !Backdrops PORTRAIT
      let backdrops_portrait: any = [];
      backdrops_portrait = [...backdrops_portrait, ...res.data.posters.filter((i: any) => i.iso_639_1 === null)];
      backdrops_portrait = [...backdrops_portrait, ...res.data.posters.filter((i: any) => i.iso_639_1 === "es")];
      backdrops_portrait = [...backdrops_portrait, ...res.data.posters.filter((i: any) => i.iso_639_1 === "en")];
      if (template.original_language !== "en" && template.original_language !== "en") {
        backdrops_portrait = [
          ...backdrops_portrait,
          ...res.data.posters.filter((i: any) => i.iso_639_1 === template.original_language),
        ];
      }
      // *set backdrops_portrait
      template = {
        ...template,
        data_game: { ...template.data_game, backdrops_portrait },
      };
      // !Logos
      let logos: any = [];
      logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === "es")];
      logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === "en")];
      logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === null)];
      if (template.original_language !== "en" && template.original_language !== "en") {
        logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === template.original_language)];
      }

      // !Posters
      let posters: any = [];
      posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === "es")];
      posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === "en")];
      if (template.original_language !== "en" && template.original_language !== "en") {
        posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === template.original_language)];
      }
      posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === null)];

      template = {
        ...template,
        backdrops: backdrops,
        backdrops_portrait: backdrops_portrait,
        posters: posters,
        logos: logos,
      };
      // !Posters Landscape
      let posters_landscape: any = [];
      posters_landscape = [...posters_landscape, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "es")];
      posters_landscape = [...posters_landscape, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "en")];
      if (template.original_language !== "en" && template.original_language !== "en") {
        posters_landscape = [
          ...posters_landscape,
          ...res.data.backdrops.filter((i: any) => i.iso_639_1 === template.original_language),
        ];
      }
      posters_landscape = [...posters_landscape, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === null)];

      //       template = {
      //         ...template,
      //         backdrops: backdrops,
      //         backdrops_portrait: backdrops_portrait,
      //         posters: posters,
      //         logos: logos,
      //         posters_landscape: posters_landscape,
      //       };
      setState(template);
//       console.log(template);
      setSpinner(false);
      //       BlobImage(tmdb_uri_img_blob, backdrops[0]?.file_path, setBackdrop);
      //       BlobImage(tmdb_uri_img_blob, backdrops_portrait[0]?.file_path, setBackdropPortrait);
      //       BlobImage(tmdb_uri_img_blob, logos[0]?.file_path, setLogo);
      //       BlobImage(tmdb_uri_img_blob, posters_landscape[0]?.file_path, setPosterLandscape);
      //       BlobImage(tmdb_uri_img_blob, posters[0]?.file_path, setPoster);
    })
    .catch(function (error) {
      console.log(error);
    });
};
