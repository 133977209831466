import { useAppSelector } from "../../../app/hooks";
import Spinner from "../../atoms/Spinner";
import useCrudTv from "../../../hooks/useCrudTv";
import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import TemplateEpisode from "./TemplateEpisode";
import useEpisode from "./useEpisode";

import useCrudEpisode from "hooks/useCrudEpisode";
import { downloadEpisode } from "libs/downloadEpisode";

export default function DOWNLOAD_EPISODE() {
  const params = useParams();

  // !Context OUTLET
  const [URI] = useOutletContext<any>();
  const { state, setState, handleChange, formData, thumbnail, setThumbnail } = useEpisode();
  const { DOWNLOAD_POST_FORM_DATA, uploading } = useCrudEpisode();

  // !Handle SUBMIT POST
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    DOWNLOAD_POST_FORM_DATA(URI, formData);
  };

  // !Params TMDB
  //   const api_key = "eafee52978a6fa33af7118d81011116b";
  const api_key = "50bee1ebe32fd4c7280a08d1dafc1a84";

  const token =
    "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJlYWZlZTUyOTc4YTZmYTMzYWY3MTE4ZDgxMDExMTE2YiIsInN1YiI6IjYzMjY4MzdiNjdiNjEzMDA3Yjg5MzA1MSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.DkYIhC6xJOI2qoaHyD1OVOXVBcZRKaeDXm6In9d0srM";
  //   const tmdb_uri_poster = "https://image.tmdb.org/t/p/w1280_and_h720_bestv2";
  const tmdb_uri_poster = "https://image.tmdb.org/t/p/original";

  useEffect(() => {
    downloadEpisode(
      params.tvId,
      params.tmdb_id,
      api_key,
      token,
      tmdb_uri_poster,
      setThumbnail,
      setState,
      params.season_number,
      params.episode_number
    );
  }, []);

  // !uploading data
  if (uploading) {
    return <Spinner position="absolute" />;
  }

  return (
    <TemplateEpisode
      titleText="Crear episodio usando API"
      buttonText="Crear episodio usando API"
      titleImg="Imagen"
      state={state}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      readMode={false}
      thumbnail={thumbnail}
      setThumbnail={setThumbnail}
    />
  );
}
