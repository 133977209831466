import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";

import axios from "axios";
import { useEffect, useState } from "react";
import headers from "libs/headers";
import { useAppSelector } from "app/hooks";
import { selectLogin } from "features/login/loginSlice";

import TableUsers from "components_admin/molecules/tables/TableUsers";
import DashboardUsers from "components_admin/molecules/dashboards/DashboardUsers";
import ButtonCreate from "components_admin/atoms/ButtonCreate";
import Spinner from "components_admin/atoms/Spinner";
import useCode from "./useCode";
import TableCodes from "./table/TableCodes";
const AllCodesSt = styled.div`
  position: relative;
  display: grid;
`;

export default function ALL_CODES() {
  const { state, setState, docs, handleChange, POST, GET_ALL, postSpinner, getSpinner, getAllSpinner } = useCode();

  useEffect(() => {
    GET_ALL();
  }, []);

  if (getAllSpinner) return <Spinner position="relative" />;

  return (
    <AllCodesSt>
      <TableCodes docs={docs} />
      <ButtonCreate />
      <Outlet context={[GET_ALL]} />
    </AllCodesSt>
  );
}
