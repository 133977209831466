import { useAppSelector } from "../../../app/hooks";
import styled from "styled-components";
import { useState, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import BottomArrowIcon from "img/BottomArrowIcon.png";
import { FiSearch } from "react-icons/fi";
import { DashboardSt } from "styles/components/templates/DashBoardSt";
const DashboardApiSt = styled(DashboardSt)``;
interface props {
  firstFetch: any;
  type: any;
  setType: any;
  search: any;
  setSearch: any;
  state: any;
}

export default function DashboardApi(props: props) {
  const search_ref = useRef(null);
  // !States
  const [search, setSearch] = useState("");
  // !Handle change con busqueda automarica cada .5seg
  const timerRef = useRef<any>(null);
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearch(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      props.setSearch(value);
      //       props.firstFetch();
    }, 1500);
  };

  return (
    <DashboardApiSt>
      {/* // !SEARCH INPUT */}
      <div className="search_input_container">
        <FiSearch className="search_icon" />
        <input
          className="search_input"
          type="text"
          ref={search_ref}
          value={search}
          onChange={handleChangeSearch}
          onFocus={(e) => e.currentTarget.select()}
        />
      </div>

      {/* <form>
        <select name={props.type} onChange={(e) => props.setType(e.currentTarget.value)}>
          <option value="movie">movie</option>
          <option value="tv">tv</option>
        </select>

        <input
          type="text"
          value={props.search}
          onChange={(e) => props.setSearch(e.currentTarget.value)}
          onFocus={(e) => e.currentTarget.select()}
        /> */}
      {/* <button
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          //     props.setQueries()
          props.firstFetch();
        }}
      >
        Buscar
      </button> */}
      {/* // !SEARCH PARAMS */}
      <section className="search_options_container">
        <div className="search_option">
          Títulos <section className="circle_number">{props.state.total_results}</section>
        </div>
        <select
          name={props.type}
          className="search_option arrow"
          //   onChange={(e) => props.setOptions({ ...props.options, folder: e.currentTarget.value })}
          onChange={(e) => props.setType(e.currentTarget.value)}
        >
          <option value="movie">movie</option>
          <option value="tv">tv</option>
        </select>
      </section>
      {/* </form> */}

      {/* <div className="cell">
        <label className="label center">ASDASD</label>
        <div className={`input center `}>{props.state.total_results}</div>
      </div> */}
    </DashboardApiSt>
  );
}
