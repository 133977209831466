export interface episode {
  thumbnail_path: string;
  name: string;
  name_es: string;
  air_date: string;
  season_number: number;
  episode_number: number;
  overview: string;
  overview_es: string;
  tmdb_id: number; //only for admin
  titleId: number;
  runtime: number;
  vote_average: number;

  // !Admin
  link: string;
  resolution: number;
  audio: string;
  available: boolean;
  id: number;
  type: string;
  start_intro: number;
  end_intro: number;
}

export const templateEpisode: episode = {
  thumbnail_path: "",
  name: "",
  name_es: "",
  air_date: "",
  season_number: 0,
  episode_number: 0,
  overview: "",
  overview_es: "",
  tmdb_id: 0, //only for admin
  titleId: 0,
  runtime: 0,
  vote_average: 0,
  // !Admin
  link: "",
  resolution: 1080,
  audio: "multilenguaje",
  available: true,
  id: 0,
  type: "episode",
  start_intro: 0,
  end_intro: 0,
};
