import { useAppSelector } from "app/hooks";
import axios from "axios";
import { selectLogin } from "features/login/loginSlice";
import headers from "libs/headers";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import DeleteIcon from "../../icons/DeleteIcon";

const ButtonCleanSt = styled.div`
  position: absolute;
  bottom: 4rem;
  width: 3rem;
  height: 3rem;
  background: #5b00da;
  justify-self: center;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0.5rem;
  :hover {
    background: #4d00b9;
  }
  .sysIcon {
    width: 100%;
    height: 100%;
  }
`;
interface props {
  fetch: any;
}
export default function ButtonClean(props: props) {
  const login = useAppSelector(selectLogin);
  // !Fetch movies
  const CLEAN_ALL = async () => {
    await axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/clean_guests_admin`, headers(login.token))
      .then(function (res) {
        props.fetch();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <ButtonCleanSt onClick={CLEAN_ALL}>
      <DeleteIcon className="sysIcon" />
    </ButtonCleanSt>
  );
}
