import Spinner from "../atoms/Spinner";
import useCrudMovie from "../../hooks/useCrudMovie";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
const DeleteSt = styled.div`
  background: #000000;
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  .title-delete {
    font-size: 2rem;
    color: white;
    text-align: center;
    font-family: "Inter_700";
  }
  .container-buttons-delete {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .buttons-delete {
      color: white;
      min-width: 10rem;
      font-size: 1.5rem;
      margin: 1rem;
      padding: 1rem 0.5rem;
      border-style: none;
      border-radius: 0.5rem;
      background: #6a00ff;
      font-family: "Inter_700";
      :hover {
        background: #5200c5;
      }
    }
  }
`;
const DeleteRecod = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleteUri, GET_ALL_MOVIES] = useOutletContext<any>();

  // !Use CRUD
  const { DELETE, uploading } = useCrudMovie();

  if (uploading) {
    return <Spinner position="absolute" />;
  }
  return (
    <DeleteSt>
      <p className="title-delete">¿Estás seguro de que quieres borrar?</p>
      <div className="container-buttons-delete">
        <button className="buttons-delete" onClick={() => DELETE(deleteUri, `${id}`, GET_ALL_MOVIES)}>
          Borrar
        </button>
        <button className="buttons-delete" onClick={() => navigate(-1)}>
          Cancelar
        </button>
      </div>
    </DeleteSt>
  );
};

export default DeleteRecod;
