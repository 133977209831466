import { useAppSelector } from "app/hooks";
import axios from "axios";
import { log } from "console";
import { selectLogin } from "features/login/loginSlice";
import headers from "libs/headers";
import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-hot-toast";
import { Outlet, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { SpinnerCircularFixed } from "spinners-react";
import styled from "styled-components";
import { GrFormClose, GrFormEdit } from "react-icons/gr";
import ScrollSt from "styles/components/templates/ScrollSt";
import { parseDate } from "libs/parseDate";
import { MdEdit } from "react-icons/md";
const SubscriptionSt = styled(ScrollSt)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  .page_profile_grid_title {
    width: 90%;
    height: auto;
    margin-top: 2rem;
    font-family: "Street_700";
    font-size: 1rem;
    color: white;
    text-align: start;
    padding: 0.5rem 0rem;
    @media only screen and (max-width: 568px) {
      width: 95%;
      margin-top: 0rem;
    }
  }
  .profile_plans_grid {
    width: 90%;
    height: 11rem;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 19rem;
    grid-template-rows: 6.5rem;
    gap: 1rem;
    justify-content: flex-start;
    align-content: center;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0.5rem 0rem;
    @media only screen and (max-width: 568px) {
      width: 95%;
    }
    // !scroll behavior
    scroll-behavior: smooth;
    // !Firefox
    scrollbar-color: #212121 #0a0a0a;
    scrollbar-width: thin;
    // !Chrome
    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }
    &::-webkit-scrollbar-track {
      background: #000000;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.2rem;
      background: #212121;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3b3b3b;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: rgb(41, 41, 41);
    }
    .profile_plan {
      width: 100%;
      height: 100%;
      background: #0e0e0e;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      position: relative;
      .plan_expiration {
        font-size: 0.8rem;
        line-height: 1.5rem;
        font-family: "Street_500";
        color: rgb(180, 180, 180);
      }
      .plan_screens {
        font-size: 1.1rem;
        line-height: 2rem;
        font-family: "Street_700";
        color: rgb(255, 255, 255);
      }
      .plan_status {
        color: #00ffbb;
        position: absolute;
        bottom: 0.7rem;
        right: 0.7rem;
        font-size: 0.8rem;
        font-family: "Street_500";
      }
      .button_delete_subscription {
        background: #ffffff;
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: -0.5rem;
        top: -0.5rem;
        border-radius: 100%;
        display: grid;
        place-content: center;
        cursor: pointer;
        :hover {
          background: #d2d2d2;
        }
        color white .icon_delete {
          width: 100%;
          height: 100%;
        }
      }
      .edit {
        top: 2rem;
      }
    }
  }

  .plans_title_container {
    width: 90%;
    height: auto;
    /* margin: auto; */
    margin-top: 2rem;
    font-family: "Street_700";
    font-size: 1rem;
    color: white;
    text-align: start;
    padding: 0.5rem 0rem;
    @media only screen and (max-width: 568px) {
      width: 95%;
    }
  }

  .plans_container {
    width: 90%;
    height: auto;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 16rem;
    grid-template-rows: 17rem;
    gap: 1rem;
    justify-content: flex-start;
    align-content: center;
    /* margin: auto; */
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0.5rem 0rem;
    @media only screen and (max-width: 568px) {
      width: 95%;
    }
    // !scroll behavior
    scroll-behavior: smooth;
    // !Firefox
    scrollbar-color: #212121 #0a0a0a;
    scrollbar-width: thin;
    // !Chrome
    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }
    &::-webkit-scrollbar-track {
      background: #000000;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.2rem;
      background: #212121;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #3b3b3b;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: rgb(41, 41, 41);
    }
    .plan {
      width: 16rem;
      height: 16rem;
      background: #0e0e0e;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 2rem;
      .plan_title {
        font-family: "Street_700";
        font-size: 1.5rem;
        color: white;
      }
      .plan_subtitle {
        font-family: "Street_500";
        font-size: 0.8rem;
        color: #a7a7a7;
      }
      .plan_currency {
        font-family: "Street_700";
        font-size: 1rem;
        margin-top: 0.2rem;
        color: #e2e0e0;
      }
      .plan_price {
        font-family: "Street_700";
        font-size: 3.5rem;
        line-height: 3.5rem;
        color: white;
      }
      .plan_button {
        margin-top: 1.5rem;
        font-size: 1rem;
        font-family: "Street_700";
        justify-self: center;
        align-self: center;
        padding: 0.5rem 1rem;
        border-radius: 0.3rem;
        background: #6200ff;
        color: white;
        border-style: none;
        transition: 0.1s;

        &:hover {
          background: #5900e7;
          transition: 0.1s;
        }
      }
    }
  }
`;
const LoadingSt = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: rgb(0, 0, 0);
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-content: center;
`;
type subscriptionType = {
  id: number;
  userId: number;
  screens: number;
  expiration: number;
  starts: number;
};
export default function Subscription() {
  const params = useParams();
  const navigate = useNavigate();
  const login = useAppSelector(selectLogin);
  const [USER_URI, GET_ALL_TITLES] = useOutletContext<any>();
  // !State
  const [state, setState] = useState({
    user_name: "",
    email: "",
    password: "",
    name: "",
    last_name: "",
    phone: 0,
    subscriptions: [],
  });
  const [spinner, setSpinner] = useState(true);
  // !Handle POST CREATE ORDER
  const POST = async (uri: string, user_id: any) => {
    setSpinner(true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/${uri}/${user_id}`, {}, headers(login.token))
      .then(function (res) {
        // console.log(res.data);
        setSpinner(false);
        toast.success("Suscripción creada");
        GET_ALL_TITLES();
        // window.open(res.data, "_self", "noopener,noreferrer");
      })
      .catch(function (error) {
        setSpinner(false);
        toast.error("Hubo un error");
      });
  };
  // !Create Order
  const createOrderBasic = () => {
    POST("create_order_month_basic", params.user_id);
  };
  const createOrderStandard = () => {
    POST("create_order_month_standard", params.user_id);
  };
  const createOrderPremium = () => {
    POST("create_order_month_premium", params.user_id);
  };
  // !Delete Order
  const handleDelete = async (subscription_id: number) => {
    setSpinner(true);
    await axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/subscription/${subscription_id}`, headers(login.token))
      .then((res) => {
        setSpinner(false);
        toast.success("Borrado");
        GET_ALL_TITLES();
      })
      .catch(function (error) {
        setSpinner(false);
        console.log(error);
      });
  };
  // !Get user info
  const GET_PROFILE = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/profile/${params.user_id}`, headers(login.token))
      .then(function (res) {
        // res.data.subscriptions.sort((a: any, b: any) => a.expiration - b.expiration);
        res.data.subscriptions.reverse();
        setState({ ...state, ...res.data });
        setSpinner(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // !First fetch
  useEffect(() => {
    GET_PROFILE();
  }, []);
  //   console.log(state);

  //   if (spinner)
  //     return (
  //       <LoadingSt>
  //         <SpinnerCircularFixed
  //           className="spinner"
  //           size={"2rem"}
  //           thickness={159}
  //           speed={200}
  //           color="#ebebeb"
  //           secondaryColor="rgba(149, 149, 149, 0.44)"
  //         />
  //       </LoadingSt>
  //     );
  return (
    <SubscriptionSt>
      <p className="page_profile_grid_title">Suscripción</p>
      <div className="profile_plans_grid">
        {state.subscriptions.map((i: subscriptionType, index) => (
          <section className="profile_plan" key={index}>
            <p className="plan_expiration">Empieza: {parseDate(i.starts)}</p>
            <p className="plan_expiration">Expira: {parseDate(i.expiration)}</p>
            <p className="plan_screens">
              {i.screens === 4 ? "PREMIUM" : i.screens === 2 ? "STANDARD" : i.screens === 1 ? "BASIC" : "Tester"}
            </p>
            <div className="plan_status" style={Date.now() < i.expiration ? {} : { color: "#ff004c" }}>
              {Date.now() > i.starts && Date.now() < i.expiration
                ? "En uso"
                : Date.now() < i.starts && Date.now() < i.expiration
                ? "Pendiende"
                : "Expirado"}
            </div>
            <div className="button_delete_subscription" onClick={() => navigate(`delete/${i.id}`)}>
              <GrFormClose className="icon_delete" />
            </div>
            <div
              className="button_delete_subscription edit"
              onClick={() => navigate(`/admin/subscriptions/update/${i.id}`)}
            >
              <MdEdit className="icon_delete" />
            </div>
          </section>
        ))}
      </div>
      <p className="plans_title_container">Planes mensuales</p>
      <div className="plans_container">
        <div className="plan">
          <p className="plan_title">Basic</p>
          <p className="plan_subtitle">1 pantalla</p>
          <p className="plan_currency">Bs</p>
          <h3 className="plan_price">55</h3>
          <button className="plan_button" onClick={createOrderBasic}>
            Comprar
          </button>
        </div>
        <div className="plan">
          <p className="plan_title">Standard</p>
          <p className="plan_subtitle">2 pantallas</p>
          <p className="plan_currency">Bs</p>
          <h3 className="plan_price">80</h3>
          <button className="plan_button" onClick={createOrderStandard}>
            Comprar
          </button>
        </div>
        <div className="plan">
          <p className="plan_title">Premium</p>
          <p className="plan_subtitle">4 pantallas</p>
          <p className="plan_currency">Bs</p>
          <h3 className="plan_price">100</h3>
          <button className="plan_button" onClick={createOrderPremium}>
            Comprar
          </button>
        </div>
      </div>
      <Outlet context={["api/v1/subscription_admin", GET_ALL_TITLES]} />
    </SubscriptionSt>
  );
}
