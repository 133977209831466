import { useAppSelector } from "../../../app/hooks";
import { selectLogin } from "../../../features/login/loginSlice";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { templateTitle } from "../../../types/title";
import { log } from "console";

export default function useAdvance() {
  //! States
  const [backdrop, setBackdrop] = useState<any>(null);
  const [backdropPortrait, setBackdropPortrait] = useState<any>(null);
  const [poster, setPoster] = useState<any>(null);
  const [posterLandscape, setPosterLandscape] = useState<any>(null);
  const [logo, setLogo] = useState<any>(null);
  const [state, setState] = useState<any>({ ...templateTitle });

  // !Setting type of title
//   state.type = state.folder === "msc-series-tv" ? "tv" : "movie";

  // !KEYS to send data
  const keys = Object.keys(templateTitle)?.filter((i) => i !== "data_game");

  // !FORM DATA
  const formData = new FormData();
  formData.append(`backdrop`, backdrop);
  formData.append(`backdrop_portrait`, backdropPortrait);
  formData.append(`poster`, poster);
  formData.append(`poster_landscape`, posterLandscape);
  formData.append(`logo`, logo);
  //   keys.map((i: any) => formData.append(i, state[i]));
  keys.map((i: any) => formData.append(i, typeof state[i] === "string" ? state[i].trim() : state[i]));
  formData.append(`data_game`, JSON.stringify(state.data_game));

  // !Handle Change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    let value = e.currentTarget.value;
    const name = e.currentTarget.name;
    //     value = name === "last_update" && value === "" ? new Date(value).getTime()  : value;
    //     console.log(value);

    setState({
      ...state,
      [e.currentTarget.name]: name === "last_update" ? new Date(value || Date.now()).getTime() - 14400000 : value,
    });
  };

  return {
    state,
    setState,
    handleChange,
    formData,
    backdrop,
    setBackdrop,
    backdropPortrait,
    setBackdropPortrait,
    poster,
    setPoster,
    posterLandscape,
    setPosterLandscape,
    logo,
    setLogo,
  };
}
