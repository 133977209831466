import ImagePreview from "../ImagePreview";
import InputForm from "components_admin/molecules/inputs/InputForm";
import TextareaForm from "components_admin/molecules/inputs/TextareaForm";
import SelectForm from "components_admin/molecules/inputs/SelectForm";
import FormSt from "styles/components/templates/FormSt";
import ScrollSt from "styles/components/templates/ScrollSt";
import styled from "styled-components";
import Countries from "json/Countries.json";
const ContainerFormSt = styled(ScrollSt)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  padding: 2rem 0;
  background: black;
  @media only screen and (max-width: 568px) {
    padding: 0rem 0rem;
  }
`;
interface props {
  titleText: string;
  buttonText: string;
  titleImg: string;
  state: any;
  handleChange: any;
  handleSubmit: any;
  readMode: boolean;

  thumbnail: any;
  setThumbnail: any;
}
export default function TemplateEpisode(props: props) {
  return (
    <ContainerFormSt>
      <FormSt onSubmit={(e) => props.handleSubmit(e)}>
        <p className="form_title"> {props.titleText}</p>
        <div className="form_line"></div>
        <InputForm
          label="Nombre de suario"
          type="text"
          name="user_name"
          value={props.state.user_name}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Contraseña"
          type="text"
          name="password"
          value={props.state.password}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Nombre"
          type="text"
          name="name"
          value={props.state.name}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Apellido"
          type="text"
          name="last_name"
          value={props.state.last_name}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Celular"
          type="text"
          name="phone"
          value={props.state.phone}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Correo electronico"
          type="text"
          name="email"
          value={props.state.email}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />

        <SelectForm
          label="Verificado"
          name="verified"
          value={props.state.verified}
          onChange={props.handleChange}
          options={[
            { value: "", name: "Verificado" },
            { value: "true", name: "Si" },
            { value: "false", name: "No" },
          ]}
          required={true}
        />
        <SelectForm
          label="País"
          name="country"
          value={props.state.country}
          onChange={props.handleChange}
          options={Countries}
          required={true}
        />
        <button className="form_button" type="submit">
          {props.buttonText}
        </button>
      </FormSt>
    </ContainerFormSt>
  );
}
