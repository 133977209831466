import React, { useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { useEffect } from "react";
import TableEpisodes from "components_admin/molecules/tables/TableEpisodes";
import headers from "libs/headers";
import { useAppSelector } from "app/hooks";
import { selectLogin } from "features/login/loginSlice";
import ButtonCreate from "components_admin/atoms/ButtonCreate";
import ButtonsCreateGenerate from "components_admin/atoms/ButtonsCreateGenerate";
const SeasonsPageSt = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 100%;
`;
export default function Episodes() {
  const params = useParams();
  const login = useAppSelector(selectLogin);

  // !State
  const [seasons, setSeasons] = useState({ count: 0, rows: [] });
  const [downloading, setDownloading] = useState(true);
  // !Fetch movies
  const GET_ALL_SEASONS = async () => {
    setDownloading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/episodes/${params.tvId}?page=1`, headers(login.token))
      .then(function (res) {
        setDownloading(false);
        // res.data.rows.map((i: any) => {
        //   i.poster_path = `${process.env.REACT_APP_STATIC_BUCKET}/thumbnails/w300/${i.thumbnail_path}`;
        // });

        setSeasons(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GET_ALL_SEASONS();
  }, []);

  return (
    <SeasonsPageSt>
      {/* <DashboardEpisodes state={seasons.rows} /> */}
      <TableEpisodes state={seasons} folderImg="thumbnails/w300" />
      <ButtonsCreateGenerate navigatePath={`/admin/api-season/${params.tvId}/${params.tmdb_id}`} />
      <Outlet context={["episode", GET_ALL_SEASONS]} />
    </SeasonsPageSt>
  );
}
