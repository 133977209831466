import axios from "axios";
import { useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectLogin } from "../../../features/login/loginSlice";
import headers from "../../../libs/headers";
import { useNavigate, useParams } from "react-router-dom";
import { codeTemplate } from "./typeCode";
import { toast } from "react-hot-toast";
import { DELETE_code_admin, GET_code_admin, GET_codes_admin, POST_code_admin, PUT_code_admin } from "./codes.Routes";

export default function useCode() {
  const navigate = useNavigate();
  const params = useParams();

  const login = useAppSelector(selectLogin);
  // !Outlet

  // !States
  const [state, setState] = useState({ ...codeTemplate });
  const [docs, setDocs] = useState({ count: 0, rows: [] });
  // !Spinners
  const [postSpinner, setPostSpinner] = useState(false);
  const [getSpinner, setGetSpinner] = useState(true); // init on render
  const [putSpinner, setPutSpinner] = useState(false);
  const [deleteSpinner, setDeleteSpinner] = useState(false);
  const [getAllSpinner, setGetAllSpinner] = useState(false); //  init on render

  // !HandleChange
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    setState({ ...state, [name]: value });
  };

  // !POST
  const POST = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPostSpinner(true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/${POST_code_admin}`, state, headers(login.token))
      .then(function (res) {
        navigate(-1);
        toast(res.data.message);
        setPostSpinner(false);
      })
      .catch(function (error) {
        setPostSpinner(false);
        toast.error(error.response.data.message);
      });
  };
  // !GET
  const GET = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/${GET_code_admin}/${params.codeId}`, headers(login.token))
      .then(function (res) {
        setState({ ...state, ...res.data });
        setGetSpinner(false);
      })
      .catch(function (error) {
        setGetSpinner(false);
        toast.error(error.response.data.message);
        navigate(-1);
      });
  };

  // !PUT
  const PUT = async (e: React.FormEvent<HTMLFormElement>) => {
    setPutSpinner(true);
    e.preventDefault();
    await axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/${PUT_code_admin}/${params.codeId}`, state, headers(login.token))
      .then(function (res) {
        navigate(-1);
        toast(res.data.message);
        setPutSpinner(false);
      })
      .catch(function (error) {
        // navigate(-1);
        setPutSpinner(false);
        toast.error(error.response.data.message);
      });
  };
  // !Handle DELETE
  const DELETE = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDeleteSpinner(true);
    await axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/${DELETE_code_admin}/${params.codeId}`, headers(login.token))
      .then((res) => {
        navigate(-1);
        toast(res.data.message);
        setDeleteSpinner(false);
      })
      .catch(function (error) {
        // navigate(-1);
        setDeleteSpinner(false);
        toast.error(error.response.data.message);
      });
  };
  const GET_ALL = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/${GET_codes_admin}`, headers(login.token))
      .then(function (res) {
        setDocs(res.data);
        setGetAllSpinner(false);
      })
      .catch(function (error) {
        setGetAllSpinner(false);
        console.log(error);
      });
  };
  return {
    state,
    setState,
    docs,
    setDocs,
    handleChange,
    POST,
    GET,
    PUT,
    DELETE,
    GET_ALL,
    postSpinner,
    getSpinner,
    putSpinner,
    deleteSpinner,
    getAllSpinner,
  };
}
