import { useAppSelector } from "app/hooks";
import Spinner from "components_admin/atoms/Spinner";
import useCrudMovie from "hooks/useCrudMovie";
import React from "react";
import { useOutletContext } from "react-router-dom";
import TemplateTitle from "./TemplateTitle";
import useTitle from "./useTitle";

export default function POST_TITLE() {
  // !Context OUTLET
  const [URI, fetchEmployees] = useOutletContext<any>();
  const {
    state,
    setState,
    handleChange,
    formData,
    backdrop,
    setBackdrop,
    backdropPortrait,
    setBackdropPortrait,
    poster,
    setPoster,
    posterLandscape,
    setPosterLandscape,
    logo,
    setLogo,
  } = useTitle();
  const { POST_FORM_DATA, uploading } = useCrudMovie();

  // !Handle SUBMIT POST
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    POST_FORM_DATA(URI, formData, fetchEmployees);
  };

  // !uploading data
  if (uploading) {
    return <Spinner position="absolute" />;
  }

  return (
    <TemplateTitle
      titleText="Crear título"
      buttonText="Crear título"
      titleImg="Imagen"
      state={state}
      setState={setState}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      readMode={false}
      backdrop={backdrop}
      setBackdrop={setBackdrop}
      backdropPortrait={backdropPortrait}
      setBackdropPortrait={setBackdropPortrait}
      poster={poster}
      setPoster={setPoster}
      posterLandscape={posterLandscape}
      setPosterLandscape={setPosterLandscape}
      logo={logo}
      setLogo={setLogo}
      download_from_api={false}
      update_images={false}
      show_update_button={false}
    />
  );
}
