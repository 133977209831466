import { useEffect } from "react";
import useCode from "./useCode";
import TemplateCode from "./TemplateCode";
import { useOutletContext } from "react-router-dom";
import { random_passowrd } from "libs/RandomPassword";

const POST_CODE = () => {
  const { state, setState, handleChange, POST, postSpinner, getSpinner } = useCode();
  const [GET_ALL_BACK] = useOutletContext<any>();
  // !Seting tvId to State
  useEffect(() => {
    setState({
      ...state,
      code: random_passowrd(6),
      password: random_passowrd(6),
    });
    return () => GET_ALL_BACK();
  }, []);
  return (
    <TemplateCode
      state={state}
      handleChange={handleChange}
      onSubmit={POST}
      title="Crear código"
      uploading={postSpinner}
      downloading={getSpinner}
    />
  );
};

export default POST_CODE;
