import styled from "styled-components";
import empty200 from "../../../img/empty200.png";
// !Icons
import DownloadIcon from "../../../icons/DownloadIcon";
import EyeIcon from "../../../icons/EyeIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import Pagination from "../Pagination";
import Spinner from "components_admin/atoms/Spinner";
import TableSt from "styles/components/templates/TableSt";
const TableApiSt = styled(TableSt)`
  .row {
    grid-template-columns: 6rem calc(40% - 6rem) calc(40% - 5rem) 10% 10% 5rem;
  }
  // !Estilos para DESKTOP
  @media only screen and (max-width: 568px) {
    .row {
      grid-template-columns: 6rem calc(100% - 17rem) 8rem 3rem;
    }
  }
`;
interface props {
  type: string;
  state: any;
  downloading: boolean;
  loadMore: any;
}
export default function TableApi(props: props) {
  const navigate = useNavigate();
  const tmdb_uri = "https://www.themoviedb.org/t/p/w220_and_h330_face/";

  if (props.downloading) return <Spinner position="relative" />;
  return (
    <TableApiSt>
      <section className="row fixed api">
        <div className="cell cell_header cell_center">Imagen</div>
        <div className="cell cell_header">Título</div>
        <div className="cell cell_header mobile_hidden">Título original</div>
        <div className="cell cell_header cell_center ">Fecha</div>
        <div className="cell cell_header cell_center mobile_hidden">Calificaión</div>
      </section>
      {props.state.results?.map((i: any) => (
        <section
          className="row"
          key={i.id}
          //   onClick={() => navigate(`/admin/content/create/${i.id}`)}
          //   style={{ cursor: "pointer" }}
        >
          <img className=" cell cell_thumbnail" src={!i.poster_path ? empty200 : i.poster_path} alt="" />
          <div className="cell">{props.type === "tv" ? i.name : i.title}</div>
          <div className="cell mobile_hidden">{props.type === "tv" ? i.original_name : i.original_title}</div>
          <div className="cell cell_center ">{props.type === "tv" ? i.first_air_date : i.release_date}</div>
          <div className="cell cell_center mobile_hidden">{i.vote_average}</div>

          <DownloadIcon
            className="icon"
            onClick={() => navigate(props.type === "tv" ? `download-tv/${i.id}` : `download-movie/${i.id}`)}
          />
        </section>
      ))}
      {/* {props.total_results !== 0 && <Pagination total_results={props.total_results} />} */}
      {props.state.page < props.state.total_pages ? (
        <div
          onClick={() => props.loadMore()}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#a5a5a5",
            cursor: "pointer",
          }}
        >
          Cargar mas
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#616161",
          }}
        >
          Llegaste al final
        </div>
      )}
    </TableApiSt>
  );
}
