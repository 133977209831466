import styled from "styled-components";
import BottomArrowIcon from "../img/BottomArrowIcon.png";

const FormSt = styled.form`
  width: 28rem;
  height: auto;
  background: #111111;
  margin: auto;
  /* margin-top: 2rem;
  margin-bottom: 2rem; */
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  z-index: 1;

  animation: scale-up-center 0.2s;

  @keyframes scale-up-center {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .form_title {
    font-family: "Inter_700";
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: white;
    margin-bottom: 0.5rem;
  }
  .form_sub_title {
    font-family: "Inter_500";
    font-size: 0.8rem;
    color: #c7c7c7;
    margin-bottom: 0.5rem;
  }
  .form_line {
    width: 100%;
    height: 1px;
    background: #282828;
    margin-bottom: 1rem;
  }
  .form_image {
    width: 10rem;
    height: auto;
    margin: auto;
    margin-bottom: 0.5rem;
  }

  .form_container_label_input {
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    .label {
      width: 100%;
      height: auto;
      font-family: "Inter_500";
      font-size: 0.8rem;
      line-height: 0.8rem;
      color: #c7c7c7;
      margin-bottom: 0.2rem;
    }
    .input {
      width: 100%;
      height: 3rem;
      line-height: 3rem;
      background: #000000;
      padding: 0 1rem;
      color: white;
      outline: none;
      border-style: none;
      border-radius: 0.5rem;
      font-family: "Inter_500";
      font-size: 1rem;
    }
    .textarea {
      padding: 0.8rem 1rem;
      height: 10rem;
      resize: none;
    }
    .select {
      padding: 0rem 1.5rem 0 1rem;
      // !HIDE UGLY ARROW
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-image: url(${BottomArrowIcon});
      background-repeat: no-repeat;
      background-position: calc(100% - 1rem) center;
      background-size: 1rem;
    }
    .date {
      color-scheme: dark;
      @media only screen and (max-width: 568px) {
        padding: 0rem 1.5rem 0 1rem;
        // !HIDE UGLY ARROW
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-image: url(${BottomArrowIcon});
        background-repeat: no-repeat;
        background-position: calc(100% - 1.6rem) center;
        background-size: 1rem;
      }
    }
    .custom_date {
      padding: 0rem 1.5rem 0 1rem;
      // !HIDE UGLY ARROW
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-image: url(${BottomArrowIcon});
      background-repeat: no-repeat;
      background-position: calc(100% - 1.6rem) center;
      background-size: 1rem;
    }
  }
  .form_container_checkbox_label {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    .form_container_checkbox_label__checkbox {
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 0.5rem;
    }
    .form_container_checkbox_label__label {
      font-family: "Inter_500";
      font-size: 0.8rem;
      line-height: 0.8rem;
      color: #c7c7c7;
      user-select: none;
    }
  }
  .form_button {
    width: 100%;
    height: 3rem;
    /* background: #6200ff; */
    color: white;
    border-radius: 0.5rem;
    font-family: "Inter_700";
    font-size: 1rem;
    margin-bottom: 1rem;
    transition: 0.1s;
    user-select: none;
    text-decoration: none;
    outline: none;
    border-style: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* &:hover {
      background: #5b05e7;
      transition: 0.1s;
    } */
    .spinner {
      position: absolute;
      right: 2rem;
    }
  }
  .button_white {
    background: #ffffff;
    color: black;
    &:hover {
      background: #e3e3e3;
      transition: 0.1s;
    }
  }
  .button_gray {
    background: #1a1a1a;
    color: #ffffff;
    border: 1px solid #3b3939;
    &:hover {
      background: #040404;
      transition: 0.1s;
    }
  }
  .button_green {
    background: #42b72a;
    &:hover {
      background: #3aa924;
      transition: 0.1s;
    }
  }
  .button_red {
    background: #ff0044;
    &:hover {
      background: #c40034;
      transition: 0.1s;
    }
  }
  .form_alert {
    font-family: "Inter_500";
    font-size: 0.8rem;
    color: #8a8a8a;
    margin-bottom: 1rem;
  }
  .form_success {
    font-family: "Inter_500";
    font-size: 0.8rem;
    color: #00ffbf;
    margin-bottom: 1rem;
  }
  .form_tos_text {
    font-family: "Inter_500";
    font-size: 0.7rem;
    color: #c7c7c7;
    margin-bottom: 1rem;
    .form_tos_text__link {
      font-size: 0.7rem;
      color: #c7c7c7;
      text-decoration: none;
      color: #9cc1dd;
    }
  }
  .form_forgot_password {
    font-family: "Inter_500";
    font-size: 0.8rem;
    color: #c7c7c7;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    text-align: center;
    text-decoration: none;
  }
  @media only screen and (max-width: 568px) {
    width: 95%;
    // !hide_icon_input_date
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
      /* display: none;
      -webkit-appearance: none; */
      opacity: 0;
    }
  }
`;
export default FormSt;
