import React, { useState } from "react";
import { templateUser } from "../../../types/user";

export default function useEpisode() {
  //! States
  //   const [backdrop, setBackdrop] = useState<any>(null);
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [state, setState] = useState<any>({ ...templateUser });

  // !KEYS to send data
  const keys = Object.keys(templateUser);

  // !FORM DATA
  const formData = new FormData();
  //   formData.append(`backdrop`, backdrop);
  formData.append(`thumbnail`, thumbnail);
  keys.map((i: any) => formData.append(i, typeof state[i] === "string" ? state[i].trim() : state[i]));

  // !Handle Change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    setState({ ...state, [e.currentTarget.name]: value });
  };

  return {
    state,
    setState,
    handleChange,
    formData,
    //     backdrop,
    //     setBackdrop,
    thumbnail,
    setThumbnail,
  };
}
