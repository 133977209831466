import { useAppSelector } from "../../../app/hooks";
import styled from "styled-components";
import { useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import BottomArrowIcon from "img/BottomArrowIcon.png";
import folders from "json/folders.json";
import { DashboardSt } from "styles/components/templates/DashBoardSt";
const DashboardUsersSt = styled(DashboardSt)``;
interface props {
  count: number;
  options: any;
  setOptions: any;
}

export default function DashboardUsers(props: props) {
  const search_ref = useRef(null);
  // !States
  const [search, setSearch] = useState("");
  // !Handle change con busqueda automarica cada .5seg
  const timerRef = useRef<any>(null);
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearch(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      props.setOptions({ ...props.options, search: value });
    }, 500);
  };
  return (
    <DashboardUsersSt>
      {/* // !SEARCH INPUT */}
      <div className="search_input_container">
        <FiSearch className="search_icon" />
        <input
          className="search_input"
          type="text"
          ref={search_ref}
          value={search}
          onChange={handleChangeSearch}
          onFocus={(e) => e.currentTarget.select()}
        />
      </div>
      {/* // !SEARCH PARAMS */}
      <section className="search_options_container">
        <div className="search_option">
          Usuarios <section className="circle_number">{props.count}</section>
        </div>
      </section>
    </DashboardUsersSt>
  );
}
