import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// *React Icons
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdFirstPage } from "react-icons/md";
import { MdLastPage } from "react-icons/md";
import styled from "styled-components";
const PaginationSt = styled.div`
  width: auto;
  height: auto;
  justify-self: flex-end;
  align-self: center;
  display: grid;
  grid-template-columns: repeat(5, 3rem);
  grid-template-rows: 3rem;
  gap: 0.5rem;
  margin-right: 0.5rem;
  .arrow_button {
    background: #0b0b0b;
    color: white;
    display: flex;
    border-radius: 0.5rem;
    outline: none;
    border-style: none;
    justify-content: center;
    align-items: center;
    font-family: "Inter_700";
    font-size: 1.2rem;
    :hover {
      background: #151515;
    }
    :active {
      background: #0c0c0c;
    }
    :disabled {
      background: #000000;
      color: #7e7c7c;
    }
    .sysIconArrow {
      width: 100%;
      height: 100%;
      padding: 0.6rem;
    }
  }
`;
interface props {
  count: number;
  options: any;
  setOptions: any;
}
export default function Pagination(props: props) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pageParams: any = params.get("page") ? params.get("page") : "1";
  const folderParams = params.get("folder") ? params.get("folder") : "";
  const audioParams = params.get("audio") ? params.get("audio") : "";
  const availableParams = params.get("available") ? params.get("available") : "";
  const vote_averageParams = params.get("vote_average") ? params.get("vote_average") : "";
  const certificationParams = params.get("certification") ? params.get("certification") : "";
  const pager = (page: number) => {
    //     props.setOptions({ ...props.options, page: page });
    console.log(page);

    navigate(
      `?page=${page}&folder=${folderParams}&audio=${audioParams}&available=${availableParams}&vote_average=${vote_averageParams}&certification=${certificationParams}`
    );
  };
  return (
    <PaginationSt>
      <button className="arrow_button" onClick={() => pager(1)} disabled={parseInt(pageParams) <= 1 && true}>
        <MdFirstPage className="sysIconArrow" />
      </button>

      <button className="arrow_button" onClick={() => pager(+pageParams - 1)} disabled={+pageParams <= 1 && true}>
        <MdKeyboardArrowLeft className="sysIconArrow" />
      </button>

      <button type="button" className="arrow_button">
        {+pageParams}
      </button>

      <button
        className="arrow_button"
        onClick={() => pager(+pageParams + 1)}
        disabled={+pageParams >= Math.ceil(props.count / props.options.limit) && true}
      >
        <MdKeyboardArrowRight className="sysIconArrow" />
      </button>

      <button
        className="arrow_button"
        onClick={() => pager(Math.ceil(props.count / props.options.limit))}
        disabled={+pageParams >= Math.ceil(props.count / props.options.limit) && true}
      >
        <MdLastPage className="sysIconArrow" />
      </button>
    </PaginationSt>
  );
}
