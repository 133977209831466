import styled from "styled-components";
const ScrollSt = styled.div`
  // !Estilos para Desktop
  @media only screen and (min-width: 568px) {
    // !Firefox
    scrollbar-color: #1A1A1A #000000;
    scrollbar-width: thin;
    // !Chrome
    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0rem;
    }
    &::-webkit-scrollbar-track {
      background: red;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0.1vw;
      background: #06d822;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #191616;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: rgb(41, 41, 41);
    }
  }
`;
export default ScrollSt;
