import styled from "styled-components";
export const ModalSt = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  overflow-y: scroll;
  background: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0;

  @media only screen and (max-width: 1400px) {
    padding: 2 0.5rem;
  }
  @media only screen and (max-width: 568px) {
    /* background: #000000; */
  }
`;
