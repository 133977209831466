export interface message {
  id: number;
  title_id: number;
  poster_path: string;
  title: string;
  original_title: string;
  date: string;
  user_id: number;
  user_name: string;
  message: string;
}

export const templateMessage: message = {
  id: 0,
  title_id: 0,
  poster_path: "",
  title: "",
  original_title: "",
  date: "",
  user_id: 0,
  user_name: "",
  message: "",
};
