import { BlobImage } from "libs/BlobImage";
import React, { useRef, useState, useEffect, useId } from "react";
import styled from "styled-components";
import CloseIcon from "../../icons/CloseIcon";
import empty from "../../img/empty.png";
import empty200 from "img/empty200.png";
import { v4 as uuidv4 } from "uuid";
const ImagesPreviewSt = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  /* background:red; */
  .label {
    width: 100%;
    height: auto;
    font-family: "Inter_500";
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: #c7c7c7;
    margin-bottom: 0.2rem;
    text-transform: capitalize;
  }

  .container_images_preview {
    width: 100%;
    height: 37rem;
    margin: auto;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 20rem 15.5rem 3rem;
    gap: 0.5rem;
    /* background: red; */
    background: #101010;
    border-radius: 0.5rem;
    padding: 0.5rem 0.5rem;

    .preview {
      width: 100%;
      height: 100%;
      object-fit: contain;
      /* background: #101010; */
    }
    .grid_thumbnails {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: 15rem;
      grid-template-rows: 15rem;
      gap: 0.5rem;
      justify-content: flex-start;
      align-content: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;
      // !Firefox
      scrollbar-color: #212121 #101010;
      scrollbar-width: thin;
      // !Chrome
      &::-webkit-scrollbar {
        width: 0.5rem;
        height: 1rem;
      }
      &::-webkit-scrollbar-track {
        background: #101010;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0.1vw;
        background: #212121;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #3b3b3b;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
      }
      &::-webkit-scrollbar-thumb:active {
        background-color: rgb(41, 41, 41);
      }
      .thumbnail {
        width: 100%;
        height: 14rem;
        border: 2px solid #1b1a1a;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #0d0d0d;
        border-radius: 1rem;
        position: relative;
        .image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .language {
          color: white;
          font-family: "Inter_500";
          position: absolute;
          top: 0.5rem;
          left: 0.5rem;
          text-shadow: 1px 1px 2px black;
        }
        .resolution {
          color: white;
          font-family: "Inter_500";
          position: absolute;
          bottom: 0.5rem;
          text-shadow: 1px 1px 2px black;
        }
        .status {
          width: 1rem;
          height: 1rem;
          border-radius: 100%;
          background: #00ffb3;
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
      }
    }
    .input_file {
      width: 100%;
      height: 3rem;
      line-height: 3rem;
      background: #201f1f;
      padding: 0 1rem;
      color: white;
      outline: none;
      border-style: none;
      border-radius: 0.5rem;
      font-family: "Inter_500";
      font-size: 1rem;
    }
  }
`;

interface props {
  title: string;
  images: any;
  thumbnail_tmdb_uri: string;
  file: any;
  setFile: any;
  min_size: number;
  blob_tmdb_uri: string;
}
export default function ImagesPreview(props: props) {
  const [index, setIndex] = useState(0);

  // props.images.sort((a: any, b: any) => a.iso_639_1 - b.iso_639_1);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    if (props.file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.readAsDataURL(props.file);
    } else {
      setPreview(null);
    }
  }, [props.file]);
  const id = useId();
  return (
    <ImagesPreviewSt>
      <div className="label">{props.title}</div>
      <div className="container_images_preview">
        <img className="preview" src={!preview ? empty200 : preview} alt="" />
        <div className="grid_thumbnails">
          {props.images?.map((i: any, local_index: number) => (
            <div
              className="thumbnail"
              key={local_index}
              onClick={() => {
                setIndex(local_index);
                //       blobImg(`https://www.themoviedb.org/t/p/original`, `${props.images[index]?.file_path}`, props.setFile);
                BlobImage(props.blob_tmdb_uri, `${props.images[local_index]?.file_path}`, props.setFile);
              }}
              style={local_index === index ? { border: "2px solid white" } : {}}
            >
              <img className="image" src={`${props.thumbnail_tmdb_uri}${i.file_path}`} alt="" loading="lazy" />
              <section className="language"> {i.iso_639_1?.toUpperCase()}</section>
              <section className="resolution">
                {i.height}x{i.width}
              </section>
              {props.title === "backdrop" && (
                <div
                  className="status"
                  style={
                    i.height < props.min_size
                      ? { background: "#ff0059" }
                      : i.iso_639_1 !== null
                      ? { background: "#ff6600" }
                      : {}
                  }
                />
              )}
              {props.title === "poster" && (
                <div
                  className="status"
                  style={
                    i.height < props.min_size
                      ? { background: "#ff0059" }
                      : i.iso_639_1 !== "es"
                      ? { background: "#ff6600" }
                      : {}
                  }
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </ImagesPreviewSt>
  );
}
