export interface CodeIT {
  id: number;
  code: string;
  status: boolean;
  expiration: number;
  username: string;
  password: string;
}
export const codeTemplate: CodeIT = {
  id: 0,
  code: "",
  status: true,
  expiration: 0,
  username: "",
  password: "",
};
