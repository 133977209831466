import React from "react";
interface props {
  label: string;
  name: string;
  value: string;
  onChange: any;
  required: boolean;
}
export default function TextareaForm(props: props) {
  return (
    <div className="form_container_label_input ">
      <label className="label">{props.label}</label>
      <textarea
        className="input textarea"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
      ></textarea>
    </div>
  );
}
