import { useAppSelector } from "../../../app/hooks";
import styled from "styled-components";
import { useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import BottomArrowIcon from "img/BottomArrowIcon.png";
import folders from "json/folders.json";
import { DashboardSt } from "styles/components/templates/DashBoardSt";
import { useLocation, useNavigate } from "react-router-dom";
const DashboardTitlesSt = styled(DashboardSt)``;
interface props {
  count: number;
  options: any;
  setOptions: any;
}

export default function DashboardTitles(props: props) {
  const search_ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  //   const pageParams: any = params.get("page") ? params.get("page") : "1";
  const folderParams = params.get("folder") ? params.get("folder") : "";
  const audioParams = params.get("audio") ? params.get("audio") : "";
  const availableParams = params.get("available") ? params.get("available") : "";
  const vote_averageParams = params.get("vote_average") ? params.get("vote_average") : "";
  const certificationParams = params.get("certification") ? params.get("certification") : "";
  // !States
  const [search, setSearch] = useState("");
  // !Handle change con busqueda automarica cada .5seg
  const timerRef = useRef<any>(null);
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSearch(value);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      props.setOptions({ ...props.options, search: value, page: 1 });
    }, 500);
  };
  return (
    <DashboardTitlesSt>
      {/* // !SEARCH INPUT */}
      <div className="search_input_container">
        <FiSearch className="search_icon" />
        <input
          className="search_input"
          type="text"
          ref={search_ref}
          value={search}
          onChange={handleChangeSearch}
          onFocus={(e) => e.currentTarget.select()}
        />
      </div>
      {/* // !SEARCH PARAMS */}
      <section className="search_options_container">
        <div className="search_option">
          Títulos <section className="circle_number">{props.count}</section>
        </div>
        <select
          name="folder"
          className="search_option arrow"
          //   onChange={(e) => props.setOptions({ ...props.options, folder: e.currentTarget.value, page: 1 })}
          onChange={(e) =>
            navigate(
              `/admin/titles?page=1&folder=${e.currentTarget.value}&audio=${audioParams}&available=${availableParams}&vote_average=${vote_averageParams}&certification=${certificationParams}`
            )
          }
        >
          {folders.map((i) => (
            <option value={i.value} key={i.value}>
              {i.name}
            </option>
          ))}
        </select>
        <select
          name="Audio"
          className="search_option arrow"
          //   onChange={(e) => props.setOptions({ ...props.options, audio: e.currentTarget.value, page: 1 })}
          onChange={(e) =>
            navigate(
              `/admin/titles?page=1&folder=${folderParams}&audio=${e.currentTarget.value}&available=${availableParams}&vote_average=${vote_averageParams}&certification=${certificationParams}`
            )
          }
        >
          <option value="">Audio</option>
          <option value="multilenguaje">Multilenguaje</option>
          <option value="latino">Latino</option>
          <option value="castellano">Castellano</option>
          <option value="subtitulado">Subtitulado</option>
        </select>
        <select
          name="available"
          className="search_option arrow"
          //   onChange={(e) => props.setOptions({ ...props.options, available: e.currentTarget.value, page: 1 })}
          onChange={(e) =>
            navigate(
              `/admin/titles?page=1&folder=${folderParams}&audio=${audioParams}&available=${e.currentTarget.value}&vote_average=${vote_averageParams}&certification=${certificationParams}`
            )
          }
        >
          <option value="">Disponible</option>
          <option value="true">si</option>
          <option value="false">no</option>
        </select>
        <select
          name="vote_average"
          className="search_option arrow"
          //   onChange={(e) => props.setOptions({ ...props.options, vote_average: e.currentTarget.value, page: 1 })}
          onChange={(e) =>
            navigate(
              `/admin/titles?page=1&folder=${folderParams}&audio=${audioParams}&available=${availableParams}&vote_average=${e.currentTarget.value}&certification=${certificationParams}`
            )
          }
        >
          <option value="">Calificación</option>
          <option value="10">10</option>
          <option value="9">9</option>
          <option value="8">8</option>
          <option value="7">7</option>
          <option value="6">6</option>
          <option value="5">5</option>
          <option value="4">4</option>
          <option value="3">3</option>
          <option value="2">2</option>
          <option value="1">1</option>
          <option value="0">NC</option>
        </select>
        <select
          name="certification"
          className="search_option arrow"
          //   onChange={(e) => props.setOptions({ ...props.options, certification: e.currentTarget.value, page: 1 })}
          onChange={(e) =>
            navigate(
              `/admin/titles?page=1&folder=${folderParams}&audio=${audioParams}&available=${availableParams}&vote_average=${vote_averageParams}&certification=${e.currentTarget.value}`
            )
          }
        >
          <option value="">Certificado</option>
          <option value="G">G</option>
          <option value="PG">PG</option>
          <option value="PG-13">PG-13</option>
          <option value="R">R</option>
          <option value="NC-17">NC-17</option>
          <option value="NR">NR</option>
        </select>
      </section>
    </DashboardTitlesSt>
  );
}
