export interface title {
  backdrop_path: string;
  backdrop_portrait_path: string;
  poster_path: string;
  poster_landscape_path: string;
  logo_path: string;
  title: string;
  title_es: string;
  title_en: string;
  original_title: string;
  original_language: string;
  last_update: number;
  date: string;
  runtime: number;
  vote_average: number;
  genres: string;
  production_companies: string;
  production_countries: string;
  tmdb_id: number; //hidded
  collection_id: number;
  imdb_id: string;
  tagline: string;
  tagline_es: string;
  overview: string;
  overview_es: string;
  budget: number;
  revenue: number;

  // !credits fetch
  cast: string;
  director: string;

  // !keywords fetch
  keywords: string;

  // !release_dates fetch
  certification: string;

  // !Admin
  type: string; //hidden
  folder: string;
  keywords_2: string;
  link: string;
  resolution: number;
  audio: string;
  available: boolean;
  id: number;

  // !only for local
  backdrops: [];
  backdrops_portrait: [];
  posters: [];
  posters_landscape: [];
  logos: [];
  data_game: {};
}

export const templateTitle: title = {
  backdrop_path: "",
  backdrop_portrait_path: "",
  poster_path: "",
  poster_landscape_path: "",
  logo_path: "",
  title: "",
  title_es: "",
  title_en: "",
  original_title: "",
  original_language: "",
  last_update: 0,
  date: "",
  runtime: 0,
  genres: "",
  production_companies: "",
  production_countries: "",
  tagline: "",
  tagline_es: "",
  overview: "",
  overview_es: "",
  vote_average: 0,
  budget: 0,
  revenue: 0,
  tmdb_id: 0, //only for admin
  collection_id: 0,
  imdb_id: "",
  // !credits fetch
  cast: "",
  director: "",

  // !keywords fetch
  keywords: "",

  // !release_dates fetch
  certification: "",

  // !Admin
  type: "", //only for admin
  folder: "",
  keywords_2: "",
  link: "",
  resolution: 1080,
  audio: "multilenguaje",
  available: true,
  id: 0,
  // !only for local
  backdrops: [],
  backdrops_portrait: [],
  posters: [],
  posters_landscape: [],
  logos: [],
  data_game: {},
};
