import { useAppSelector } from "../../../app/hooks";
import Spinner from "../../atoms/Spinner";
import useCrudMovie from "../../../hooks/useCrudMovie";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import useCrudMessage from "hooks/useCrudMessage";
import TemplateMessage from "./TemplateMessage";
import useMessage from "./useMessage";

export default function PUT_MESSAGE() {
  const params = useParams();
  // !Context OUTLET
  const [URI, fetchEmployees] = useOutletContext<any>();

  // !Hooks
  const { state, setState, handleChange, formData, thumbnail, setThumbnail } = useMessage();
  const { GET, PUT_FORM_DATA, downloading, uploading } = useCrudMessage();

  // !Handle SUBMIT POST
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    PUT_FORM_DATA(URI, `${params.id}`, formData, fetchEmployees);
  };

  // !Params
  const movie_id = params.id || "";
  useEffect(() => {
    GET(URI, `${movie_id}`, setState, setThumbnail); //TODO
  }, []);

  if (downloading) {
    return <Spinner position="absolute" />;
  }
  if (uploading) {
    return <Spinner position="absolute" />;
  }
  return (
    <TemplateMessage
      titleText="Mensaje"
      buttonText="Cerrar"
      titleImg="Imagen"
      state={state}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      readMode={false}
      thumbnail={thumbnail}
      setThumbnail={setThumbnail}
    />
  );
}
