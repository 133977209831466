import { useAppSelector } from "../../../app/hooks";
import { selectLogin } from "../../../features/login/loginSlice";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { templateMessage } from "../../../types/message";

export default function useMessage() {
  //! States
  //   const [backdrop, setBackdrop] = useState<any>(null);
  const [thumbnail, setThumbnail] = useState<any>(null);
  const [state, setState] = useState<any>({ ...templateMessage });

  // !KEYS to send data
  const keys = Object.keys(templateMessage);

  // !FORM DATA
  const formData = new FormData();
  //   formData.append(`backdrop`, backdrop);
  formData.append(`thumbnail`, thumbnail);
  keys.map((i: any) => formData.append(i, state[i]));

  // !Handle Change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    setState({ ...state, [e.currentTarget.name]: value });
  };

  return {
    state,
    setState,
    handleChange,
    formData,
    //     backdrop,
    //     setBackdrop,
    thumbnail,
    setThumbnail,
  };
}
