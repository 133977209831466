import styled from "styled-components";
// !Icons
import DeleteIcon from "../../../../icons/DeleteIcon";
import { useNavigate } from "react-router-dom";
import TableSt from "styles/components/templates/TableSt";
import { parseDate } from "libs/parseDate";
const TableCodesSt = styled(TableSt)`
  .row {
    grid-template-columns: calc(40% - 5rem) 30% 15% 15% 5rem;
  }
  // !Estilos para DESKTOP
  @media only screen and (max-width: 568px) {
    .row {
      grid-template-columns: calc(40% - 3rem) 20% 40% 3rem;
    }
  }
`;

interface props {
  docs: any;
}
export default function TableCodes(props: props) {
  const navigate = useNavigate();

  return (
    <TableCodesSt>
      <section className="row fixed">
        <div className="cell cell_header">Código</div>
        <div className="cell cell_header mobile_hidden">Usuario</div>
        <div className="cell cell_header cell_center">Estado</div>
        <div className="cell cell_header cell_center">Expira</div>
      </section>

      {props.docs.rows.map((i: any) => (
        <section className="row" key={i.id}>
          <div className="cell">{i.code}</div>
          <div className="cell cell_header mobile_hidden">{i.username}</div>
          <div className="cell cell_center" style={i.status === false ? { color: "#ff004c" } : { color: "#00ff95" }}>
            {i.status ? "Disponible" : "Usado"}
          </div>
          <div
            className="cell cell_center"
            style={i.expiration < Date.now() ? { color: "#ff004c" } : { color: "#00ff95" }}
          >
            {parseDate(i.expiration)}
          </div>
          <DeleteIcon className="icon" onClick={() => navigate(`delete/${i.id}`)} />
        </section>
      ))}
    </TableCodesSt>
  );
}
