import styled from "styled-components";
import empty200 from "../../../img/empty200.png";
// !Icons
import EditIcon from "../../../icons/EditIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { FaFolder } from "react-icons/fa";
import Pagination from "components_admin/atoms/Pagination";
import TableSt from "styles/components/templates/TableSt";
import { parseDate } from "libs/parseDate";
const TableTitlesSt = styled(TableSt)`
  .row {
    grid-template-columns: 6rem calc(20% - 6rem) 15% calc(35% - 15rem) 10% 10% 10% 5rem 5rem 5rem;
  }
  // !Estilos para DESKTOP
  @media only screen and (max-width: 568px) {
    .row {
      grid-template-columns: 6rem calc(100% - 21rem) 6rem  3rem 3rem 3rem;
    }
  }
`;

interface props {
  moviesDocs: any;
  options: any;
  setOptions: any;
  folderImg: string;
}
export default function TableTitles(props: props) {
  const navigate = useNavigate();

  return (
    <TableTitlesSt>
      <section className="row fixed">
        <div className="cell cell_header cell_center">Imagen</div>
        <div className="cell cell_header">Título</div>
        <div className="cell cell_header mobile_hidden">Folder</div>
        <div className="cell cell_header mobile_hidden">Link</div>
        <div className="cell cell_header cell_center mobile_hidden">Año</div>
        <div className="cell cell_header cell_center">Tamaño</div>
        <div className="cell cell_header cell_center mobile_hidden">Disponible</div>
      </section>

      {props.moviesDocs.rows.map((i: any) => (
        <section className="row" key={i.id}>
          <img
            className="cell cell_thumbnail"
            src={
              i.poster_path === ""
                ? empty200
                : `${process.env.REACT_APP_STATIC_BUCKET}/${props.folderImg}/${i.poster_path}`
            }
            alt=""
          />
          <div className="cell">{i.title}</div>

          <div className="cell mobile_hidden">{i.folder}</div>
          <div className="cell mobile_hidden">{decodeURIComponent(i.link)}</div>
          <div className="cell cell_center mobile_hidden">{new Date(i.date).getFullYear()}</div>
          {/* <div className="cell cell_center mobile_hidden">{i.collection_id}</div> */}
          <div
            className="cell cell_center"
            style={
              i.episodes.map(({ size }: any) => size).reduce((acc: any, value: any) => acc + value, 0) / 1073741824 !==
                0 && i.type === "tv"
                ? { color: "#ffd000" }
                : i.video_info !== null && i.type === "movie"
                ? { color: "#00ffbb" }
                : { color: "#ff0059" }
            }
          >
            {i.type === "movie"
              ? `${(i.video_info?.format?.size / 1073741824)?.toFixed(2) || 0} Gb`
              : `${(
                  i.episodes.map(({ size }: any) => size).reduce((acc: any, value: any) => acc + value, 0) / 1073741824
                )?.toFixed(2)} Gb`}
          </div>
          <div
            className="cell cell_center mobile_hidden"
            style={i.available ? { color: "#00ffbb" } : { color: "#ff0059" }}
          >
            {i.available === true ? "Si" : "No"}
          </div>

          {i.type === "tv" ? (
            <FaFolder className="icon" onClick={() => navigate(`/admin/episodes/${i.id}/${i.tmdb_id}`)} />
          ) : (
            <div></div>
          )}
          <EditIcon className="icon" onClick={() => navigate(`update/${i.id}`)} />
          <DeleteIcon className="icon" onClick={() => navigate(`delete/${i.id}`)} />
        </section>
      ))}
      <Pagination count={props.moviesDocs.count} options={props.options} setOptions={props.setOptions} />
    </TableTitlesSt>
  );
}
