import ImagePreview from "../ImagePreview";
import InputForm from "components_admin/molecules/inputs/InputForm";
import TextareaForm from "components_admin/molecules/inputs/TextareaForm";
import SelectForm from "components_admin/molecules/inputs/SelectForm";
import FormSt from "styles/components/templates/FormSt";
import ScrollSt from "styles/components/templates/ScrollSt";
import styled from "styled-components";
const ContainerFormSt = styled(ScrollSt)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  padding: 2rem 0;
  background: black;
  @media only screen and (max-width: 568px) {
    padding: 0rem 0rem;
  }
`;
interface props {
  titleText: string;
  buttonText: string;
  titleImg: string;
  state: any;
  handleChange: any;
  handleSubmit: any;
  readMode: boolean;

  thumbnail: any;
  setThumbnail: any;
}
export default function TemplateMessage(props: props) {
  return (
    <ContainerFormSt>
      <FormSt onSubmit={(e) => props.handleSubmit(e)}>
        <p className="form_title"> {props.titleText}</p>
        <p className="form_sub_title">Recuerda solucionar los problemas, lo mas antes posible.</p>
        <div className="form_line"></div>
        {/* <ImagePreview file={props.backdrop} setFile={props.setBackdrop} /> */}
        <ImagePreview file={props.thumbnail} setFile={props.setThumbnail} />
        <InputForm
          label="Título"
          type="text"
          name="title"
          value={props.state.title}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Título original"
          type="text"
          name="original_title"
          value={props.state.original_title}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Fecha de estreno"
          type="text"
          name="date"
          value={props.state.date}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Usuario"
          type="text"
          name="user_name"
          value={props.state.user_name}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />

        <TextareaForm
          label="Mensaje"
          name="message"
          value={props.state.message}
          onChange={props.handleChange}
          required={false}
        />

        <button
          className="form_button"
          type="submit"
          style={!props.thumbnail ? { background: "red" } : { background: "#6200ff" }}
        >
          {props.buttonText}
        </button>
      </FormSt>
    </ContainerFormSt>
  );
}
