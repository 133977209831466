import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AdditionIcon from "../../icons/AdditionIcon";
import WebIcon from "icons/WebIcon";

const ButtonsCreateGenerateSt = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 8rem;
  height: 3.5rem;
  justify-self: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;

  .sysIcon {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
    background: #6a00ff;

    &:hover {
      background: #5c00dc;
    }
  }
`;
interface props {
  navigatePath: string;
}
export default function ButtonsCreateGenerate(props: props) {
  const navigate = useNavigate();

  return (
    <ButtonsCreateGenerateSt>
      <AdditionIcon className="sysIcon" onClick={() => navigate("create")} />
      <WebIcon className="sysIcon" onClick={() => navigate(props.navigatePath)} />
    </ButtonsCreateGenerateSt>
  );
}
