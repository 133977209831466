import { useAppSelector } from "../../../app/hooks";
import { selectLogin } from "../../../features/login/loginSlice";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { templateSubscription } from "../../../types/subscription";
import { log } from "console";

export default function useAdvance() {
  //! States
  const [backdrop, setBackdrop] = useState<any>(null);
  const [poster, setPoster] = useState<any>(null);
  const [state, setState] = useState<any>({ ...templateSubscription });
  state.screens = state.plan === "premium" ? 4 : state.plan === "standard" ? 2 : 1;
  // !Setting type of title
  state.type = state.folder === "msc-series-tv" ? "tv" : "movie";

  // !KEYS to send data
  const keys = Object.keys(templateSubscription);

  // !FORM DATA
  const formData = new FormData();
  formData.append(`backdrop`, backdrop);
  formData.append(`poster`, poster);
  keys.map((i: any) => formData.append(i, state[i]));

  // !Handle Change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    setState({
      ...state,
      [e.currentTarget.name]:
        name === "starts" || name === "expiration" ? new Date(value || Date.now()).getTime() - 14400000 : value,
    });
  };

  return {
    state,
    setState,
    handleChange,
    formData,
    backdrop,
    setBackdrop,
    poster,
    setPoster,
  };
}
