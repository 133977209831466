export interface user {
  id: number;
  // !Signup client
  email: string;
  user_name: string;
  password: string;
  verified: boolean;
  country: string;
  // !Signup admin
  name: string;
  last_name: string;
  phone: string;
  role: string;
  code: number;
}

export const templateUser: user = {
  id: 0,
  // !Signup client
  email: "",
  user_name: "",
  password: "",
  verified: true,
  country: "Bolivia",
  // !Signup admin
  name: "",
  last_name: "",
  phone: "",
  role: "user",
  code: 0,
};
