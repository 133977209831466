import { useAppSelector } from "../../../app/hooks";
import Spinner from "../../atoms/Spinner";
import useCrudMovie from "../../../hooks/useCrudMovie";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TemplateTitle from "./TemplateTitle";
import useTitle from "./useTitle";

export default function PUT_TITLE_IMAGES() {
  const params = useParams();
  // !Context OUTLET
  const [URI, fetchEmployees] = useOutletContext<any>();

  // !Hooks
  const {
    state,
    setState,
    handleChange,
    formData,
    backdrop,
    setBackdrop,
    backdropPortrait,
    setBackdropPortrait,
    poster,
    setPoster,
    posterLandscape,
    setPosterLandscape,
    logo,
    setLogo,
  } = useTitle();
  const { GET, PUT_FORM_DATA, downloading, uploading } = useCrudMovie();

  // !Handle SUBMIT POST
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    PUT_FORM_DATA(URI, `${params.id}`, formData, fetchEmployees);
  };

  // !Params
  const movie_id = params.id || "";
  useEffect(() => {
    GET(URI, `${movie_id}`, setState, setBackdrop, setBackdropPortrait, setPoster, setPosterLandscape, setLogo); //TODO
  }, []);

  if (downloading) {
    return <Spinner position="absolute" />;
  }
  if (uploading) {
    return <Spinner position="absolute" />;
  }
  return (
    <TemplateTitle
      titleText="Editar imagenes"
      buttonText="Editar imagenes"
      titleImg="Imagen"
      state={state}
      setState={setState}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      readMode={false}
      backdrop={backdrop}
      setBackdrop={setBackdrop}
      backdropPortrait={backdropPortrait}
      setBackdropPortrait={setBackdropPortrait}
      poster={poster}
      setPoster={setPoster}
      posterLandscape={posterLandscape}
      setPosterLandscape={setPosterLandscape}
      logo={logo}
      setLogo={setLogo}
      download_from_api={true}
      update_images={true}
      show_update_button={false}
    />
  );
}
