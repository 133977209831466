import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import Spinner from "../atoms/Spinner";
import ButtonCreate from "../atoms/ButtonCreate";
import axios from "axios";
import { useEffect, useState } from "react";
import headers from "libs/headers";
import { useAppSelector } from "app/hooks";
import { selectLogin } from "features/login/loginSlice";
import TableTitles from "components_admin/molecules/tables/TableTitles";
import DashboardTitles from "components_admin/molecules/dashboards/DashboardTitles";
import { log } from "console";
import TableMessages from "components_admin/molecules/tables/TableMessages";
const MessagesPageSt = styled.div`
  position: relative;
  display: grid;


`;

export default function Messages() {
  const login = useAppSelector(selectLogin);
  const location = useLocation();
  const queries = new URLSearchParams(location.search);

  // !State
  const [moviesDocs, setMoviesDocs] = useState({
    rows: [],
    count: 0,
  });
  const [options, setOptions] = useState({
    search: "",
    folder: "",
    available: "",
    vote_average: "",
    certification: "",
    limit: 12,
    page: 1,
  });
  const [downloading, setDownloading] = useState(false);

  // !OPTIONS QUERIES

  // !Fetch movies
  const GET_ALL_DOCS = async () => {
    setDownloading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/messages_admin`, headers(login.token))
      .then(function (res) {
        setDownloading(false);
        setMoviesDocs(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GET_ALL_DOCS();
  }, [options]);

  //   if (downloading) return <Spinner position="relative" />;

  return (
    <MessagesPageSt>
      {/* <DashboardTitles count={moviesDocs.count} options={options} setOptions={setOptions} /> */}
      <TableMessages messagesDocs={moviesDocs} folderImg="posters/w250" />
      {/* <ButtonCreate /> */}
      <Outlet context={["api/v1/message_admin", GET_ALL_DOCS]} />
    </MessagesPageSt>
  );
}
