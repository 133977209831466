import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "./app/hooks";
import { selectLogin, setLogin } from "./features/login/loginSlice";
import useLogin from "./hooks/useLogin";

import Home from "./components/Home";
import { Toaster } from "react-hot-toast";
import Admin from "components_admin/Admin";
import Error from "components/atoms/Error";

const AppSt = styled.div`
  width: 100vw;
  height: 100vh;
  .toast {
    font-size: 4vw;
    user-select: none;
  }
  // !Estilos para DESKTOP
  @media only screen and (min-width: 568px) {
    .toast {
      font-size: 1vw;
      user-select: none;
    }
  }
`;
function App() {
  const dispatch = useDispatch();
  const login = useAppSelector(selectLogin);

  // !Local storage
  const token = localStorage.getItem("token_v2") === null ? "" : `${localStorage.getItem("token_v2")}`;

  // !Redirect
  const REDIRECT = login.token !== "" ? <Navigate to="/admin" replace /> : <Navigate to="/home" replace />;

  // !Login
  const { LOGIN } = useLogin();
  useEffect(() => {
    if (token !== null && token !== "") LOGIN(token);
  }, []);

  return (
    <AppSt>
      <Toaster
        toastOptions={{
          className: "toast",
        }}
      />
      <Router>
        <Routes>
          <Route path="/" element={REDIRECT} />
          <Route path="/home/*" element={<Home />} />
          {login.token !== "" && <Route path="/admin/*" element={<Admin />} />}
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </AppSt>
  );
}

export default App;
