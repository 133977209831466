import { useAppSelector } from "../app/hooks";
import axios from "axios";
import { selectLogin } from "../features/login/loginSlice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import headers from "libs/headers";
import { BlobImage } from "../libs/BlobImage";
import toast from "react-hot-toast";
import { templateTitle } from "types/title";

export default function useCrudTv() {
  const navigate = useNavigate();

  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const login = useAppSelector(selectLogin);
  const api_key = "eafee52978a6fa33af7118d81011116b";
  const token =
    "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJlYWZlZTUyOTc4YTZmYTMzYWY3MTE4ZDgxMDExMTE2YiIsInN1YiI6IjYzMjY4MzdiNjdiNjEzMDA3Yjg5MzA1MSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.DkYIhC6xJOI2qoaHyD1OVOXVBcZRKaeDXm6In9d0srM";

  // !Handle DOWNLOAD_POST_FORM_DATA
  const DOWNLOAD_POST_FORM_DATA = async (uri: string, formData: object) => {
    setUploading(true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/${uri}`, formData, headers(login.token))
      .then(function (res) {
        setUploading(false);
        toast.success(res.data.message);
        console.log(res.data.message);
        navigate(-1);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };

  // !Handle POST_FORM_DATA
  const POST_FORM_DATA = async (uri: string, formData: object, reloadBack: () => {}) => {
    setUploading(true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/${uri}`, formData, headers(login.token))
      .then(function (res) {
        setUploading(false);
        reloadBack();

        navigate(-1);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };

  // !Handle GET
  const GET = async (uri: string, id: string | null, setState: any, setBackdrop: any, setPoster: any) => {
    let template = templateTitle;
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/${uri}/${id}`, headers(login.token))
      .then(function (res) {
        // setDownloading(false);
        // setState(res.data);
        template = res.data;
        console.log(template);

        BlobImage(
          `${process.env.REACT_APP_STATIC_BUCKET}/backdrops_tvs/w1920`,
          res.data.backdrop_path,
          setBackdrop
        );
        BlobImage(`${process.env.REACT_APP_STATIC_BUCKET}/posters_tvs/w500`, res.data.poster_path, setPoster);
      })
      .catch(function (error) {
        setDownloading(false);
        console.log(error);
      });
    // !GET IMAGES
    await axios
      .get(`https://api.themoviedb.org/3/tv/${template.tmdb_id}/images?api_key=${api_key}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then(function (res) {
        //       console.log(res.data);
        const backdrops = res.data.backdrops.filter((i: any) => i.iso_639_1 === null);

        template = {
          ...template,
          backdrops: backdrops,
          //   posters: sortByValue(
          //     res.data.posters.filter((i: any) => i.iso_639_1 === "es" || i.iso_639_1 === "en"),
          //     "iso_639_1"
          //   ),
        };
        setState(template);
        setDownloading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // !Handle PUT_FORM_DATA
  const PUT_FORM_DATA = async (uri: string, id: string, data: object, reloadBack: () => {}) => {
    setUploading(true);
    await axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/${uri}/${id}`, data, headers(login.token))
      .then(function (res) {
        setUploading(false);
        reloadBack();
        navigate(-1);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };
  // !Handle DELETE
  const DELETE = async (uri: string, id: string, reloadBack: () => {}) => {
    setUploading(true);
    await axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/${uri}/${id}`, headers(login.token))
      .then((res) => {
        // setUploading(false);
        reloadBack();
        navigate(-1);
        toast.success("Deleted");
        // console.log(res);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };

  //   // !Fetching all docs
  //   const GET_ALL_MOVIES = async (uri: string, movies: any, setMovies: any, page: string) => {
  //     setDownloading(true);
  //     await axios
  //       .get(`${process.env.REACT_APP_BACKEND_URL}/${uri}?page=${page}`, headers(login.token))
  //       .then(function (res) {
  //         console.log(res.data);

  //         setDownloading(false);
  //         setMovies([...movies, ...res.data.rows]);
  //       })
  //       .catch(function (error) {
  //         console.log(error, "aaaaaa");

  //         checkErrors(error);
  //       });
  //   };
  return {
    DOWNLOAD_POST_FORM_DATA,
    POST_FORM_DATA,
    GET,
    PUT_FORM_DATA,
    DELETE,
    //     GET_ALL_MOVIES,
    downloading,
    uploading,
  };
}
