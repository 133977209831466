import React from "react";

interface props {
  label: string;
  type: string;
  name: string;
  value: string;
  onChange: any;
  required: boolean;
  readOnly: boolean;
}
export default function InputForm(props: props) {
  return (
    <div className="form_container_label_input">
      <label className="label">{props.label}</label>
      <input
        onWheel={(e) => e.currentTarget.blur()}
        className="input"
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
        readOnly={props.readOnly}
      />
    </div>
  );
}
