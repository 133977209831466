import styled from "styled-components";
import BottomArrowIcon from "img/BottomArrowIcon.png";
export const DashboardSt = styled.div`
  padding: 0 0.5rem;
  padding-top: 0.5rem;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 3rem calc(100% - 3rem);
  gap: 0.5rem;
  /* @media only screen and (max-width: 568px) {
    padding: 0 1rem;
  } */
  .search_input_container {
    /* width: 100%;
    height: 100%; */
    background: #1a1a1a;
    border-radius: 0.6rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .search_icon {
      width: 1.5rem;
      height: 1.5rem;
      color: #b8b8b8;
      margin-left: 1rem;
    }
    .search_input {
      width: 100%;
      height: 100%;
      position: absolute;
      background: none;
      border-style: none;
      outline: none;
      border-radius: 0.6rem;
      padding: 0 2rem 0 3.5rem;
      font-size: 1rem;
      font-family: "Inter_700";
      color: white;
    }
  }
  .search_options_container {
    /* width: 100%;
    height: 3rem; */
    overflow-x: scroll;
    overflow-y: hidden;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 10rem;
    grid-template-rows: 3rem;
    gap: 0.5rem;
    // !Firefox
    scrollbar-color: #161616 black;
    scrollbar-width: thin;
    // !Chrome
    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }
    .search_option {
      /* width: 8rem;
      height: 3rem; */
      padding: 0rem 0.5rem 0 0.5rem;
      background: #0f0f0f;
      color: white;
      /* margin-right: 0.5rem; */
      border-radius: 0.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
      font-family: "Inter_700";
      cursor: default;
      transition: 0.1s;
      user-select: none;
      border-style: none;
      outline: none;
      /* :hover {
          background: #1c1c1c;
          transition: 0.1s;
        } */
      .circle_number {
        width: auto;
        height: 1.3rem;
        line-height: 1.3rem;
        padding: 0 0.5rem;
        text-align: center;
        background: #363636;
        border-radius: 1rem;
        margin-left: 0.5rem;
        font-size: 0.6rem;
        font-family: "Inter_700";
      }
      option {
        font-size: 0.9rem;
        font-family: "Inter_700";
      }
    }
    .arrow {
      padding: 0rem 1.5rem 0 1rem;
      // !HIDE UGLY ARROW
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-image: url(${BottomArrowIcon});
      background-repeat: no-repeat;
      background-position: calc(100% - 1rem) center;
      background-size: 1rem;
    }
  }
  @media only screen and (max-width: 568px) {
    /* padding: 0 0.5rem; */
  }
`;
