import styled from "styled-components";
import empty200 from "../../../img/empty200.png";
// !Icons
import DownloadIcon from "../../../icons/DownloadIcon";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "components_admin/atoms/Spinner";
import TableSt from "styles/components/templates/TableSt";
import { FaFolder } from "react-icons/fa";

const TableApiSeasonsSt = styled(TableSt)`
  .row {
    grid-template-columns: 6rem 10rem 10rem calc(90% - 31rem) 10% 5rem;
  }
  // !Estilos para DESKTOP
  @media only screen and (max-width: 568px) {
    .row {
      grid-template-columns: 6rem calc(50% - 6rem) calc(50% - 3rem) 3rem;
    }
  }
`;
interface props {
  seasonsDocs: any;
  downloading: boolean;
}
export default function TableApiSeasons(props: props) {
  const params = useParams();
  const navigate = useNavigate();
  const tmdb_uri = "https://www.themoviedb.org/t/p/w220_and_h330_face/";

  if (props.downloading) return <Spinner position="relative" />;

  return (
    <TableApiSeasonsSt>
      <section className="row fixed api">
        <div className="cell cell_header cell_center">Imagen</div>
        <div className="cell cell_header">Temporada</div>
        <div className="cell cell_header">Episodios</div>
        <div className="cell cell_header mobile_hidden">Sinopsis</div>
        <div className="cell cell_header cell_center mobile_hidden">Fecha</div>
      </section>
      {props.seasonsDocs.map((i: any) => (
        <section
          className="row"
          key={i.id}
          //   onClick={() => navigate(`/admin/content/create/${i.id}`)}
          //   style={{ cursor: "pointer" }}
        >
          <img className=" cell cell_thumbnail" src={!i.poster_path ? empty200 : i.poster_path} alt="" />
          <div className="cell">Temporada {i.season_number}</div>
          <div className="cell">Episodios {i.episode_count}</div>
          <div className="cell mobile_hidden">{i.overview}</div>
          <div className="cell cell_center mobile_hidden">{i.air_date}</div>

          <FaFolder
            className="icon"
            onClick={() => navigate(`/admin/api-episodes/${params.tvId}/${params.tmdb_id}/${i.season_number}`)}
          />
        </section>
      ))}
    </TableApiSeasonsSt>
  );
}
