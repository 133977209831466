import styled from "styled-components";
import { NavLink } from "react-router-dom";
//! Icons
import GuestIcon from "../../icons/GuestIcon";
import CodeIcon from "../../icons/CodeIcon";
import MessageIcon from "../../icons/MessageIcon";
import ExitIcon from "../../icons/ExitIcon";
import WebIcon from "../../icons/WebIcon";
import BlazorIcon from "../../icons/BlazorIcon";
import UsersIcon from "../../icons/UsersIcon";
import TvIcon from "../../icons/TvIcon";
import ReportsIcon from "../../icons/ReportsIcon";
import { useAppSelector } from "../../app/hooks";
import ScrollSt from "styles/components/templates/ScrollSt";
import useLogin from "hooks/useLogin";

const NavbarSt = styled(ScrollSt)`
  padding: 2rem 0;
  display: grid;
  grid-auto-flow: row;
  grid-auto-columns: 5rem;
  grid-auto-rows: 5rem;
  justify-content: center;
  align-content: flex-start;
  gap: 1rem;
  border-right: 1px solid #1e1e1e;
  border-bottom: none;
  overflow-y: scroll;
  overflow-x: hidden;

  .icon-nav {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #131313;
    color: #848484;
    text-decoration: none;
    :hover {
      background: #5a00ea;
      color: white;
    }
    .sysIconCloud {
      width: 2.5rem;
      height: 2.5rem;
    }
    .link_title {
      text-decoration: none;
      font-size: 0.8rem;
      font-family: "Inter_500";
    }
  }
  .active {
    background: #6200ff;
    color: white;
    .sysIconCloud,
    .link_title {
      color: white;
    }
  }
  // !Estilos para MOBILE
  @media only screen and (max-width: 568px) {
    padding: 0 0.5rem;
    grid-auto-flow: column;
    grid-auto-columns: 3rem;
    grid-template-rows: 3rem;
    gap: 0.5rem;
    justify-content: flex-start;
    align-content: center;
    border-right: none;
    border-bottom: 1px solid #1e1e1e;

    .icon-nav {
      border-radius: 0.25rem;
      background: #050505;

      .sysIconCloud {
        width: 2rem;
        height: 2rem;
      }
      .link_title {
        display: none;
      }
    }
    .active {
      background: #6200ff;
      color: white;
      .sysIconCloud,
      .link_title {
        color: white;
      }
    }
  }
`;
const Navbar = () => {
  const { LOGOUT } = useLogin();
  return (
    <NavbarSt>
      <NavLink to="/admin/users" className="icon-nav">
        <UsersIcon className="sysIconCloud" />
        <div className="link_title">Usuarios</div>
      </NavLink>

      <NavLink to="/admin/codes" className="icon-nav">
        <CodeIcon className="sysIconCloud" />
        <div className="link_title">Códigos</div>
      </NavLink>

      <NavLink to="/admin/blazor_users" className="icon-nav">
        <BlazorIcon className="sysIconCloud" />
        <div className="link_title">Blazor</div>
      </NavLink>

      <NavLink to="/admin/titles" className="icon-nav">
        <TvIcon className="sysIconCloud" />
        <div className="link_title">Títulos</div>
      </NavLink>

      <NavLink to="/admin/api" className="icon-nav">
        <WebIcon className="sysIconCloud" />
        <div className="link_title">API</div>
      </NavLink>

      <NavLink to="/admin/subscriptions" className="icon-nav">
        <ReportsIcon className="sysIconCloud" />
        <div className="link_title">Subs</div>
      </NavLink>

      <NavLink to="/admin/messages" className="icon-nav">
        <MessageIcon className="sysIconCloud" />
        <div className="link_title">Mensajes</div>
      </NavLink>

      <NavLink to="/admin/guests" className="icon-nav">
        <GuestIcon className="sysIconCloud" />
        <div className="link_title">Invitados</div>
      </NavLink>

      <NavLink
        to="/"
        className="icon-nav"
        onClick={() => {
          LOGOUT();
        }}
      >
        <ExitIcon className="sysIconCloud" />
        <div className="link_title">Salir</div>
      </NavLink>
    </NavbarSt>
  );
};

export default Navbar;
