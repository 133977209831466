import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AdditionIcon from "../../icons/AdditionIcon";

const ButtonCreateSt = styled(Link)`
  position: absolute;
  bottom: 4rem;
  width: 3rem;
  height: 3rem;
  background: #5b00da;
  justify-self: center;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  :hover {
    background: #4d00b9;
  }
  .sysIcon {
    width: 100%;
    height: 100%;
  }
`;
export default function ButtonCreate() {
  return (
    <ButtonCreateSt to="create">
      <AdditionIcon className="sysIcon" />
    </ButtonCreateSt>
  );
}
