import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import Spinner from "../atoms/Spinner";
import ButtonCreate from "../atoms/ButtonCreate";
import axios from "axios";
import { useEffect, useState } from "react";
import headers from "libs/headers";
import { useAppSelector } from "app/hooks";
import { selectLogin } from "features/login/loginSlice";
import TableTitles from "components_admin/molecules/tables/TableTitles";
import DashboardTitles from "components_admin/molecules/dashboards/DashboardTitles";
import { log } from "console";
const TitlesPageSt = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 7.5rem calc(100% - 7.5rem);
`;

export default function Titles() {
  const login = useAppSelector(selectLogin);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // !location_params
  const pageParams = params.get("page") ? params.get("page") : "1";
  const folderParams = params.get("folder") ? params.get("folder") : "";
  const audioParams = params.get("audio") ? params.get("audio") : "";
  const availableParams = params.get("available") ? params.get("available") : "";
  const vote_averageParams = params.get("vote_average") ? params.get("vote_average") : "";
  const certificationParams = params.get("certification") ? params.get("certification") : "";
  //   console.log(pageParams, folderParams);

  // !State
  const [moviesDocs, setMoviesDocs] = useState({
    rows: [],
    count: 0,
  });
  const [options, setOptions] = useState({
    search: "",
    folder: "",
    audio: "",
    available: "",
    vote_average: "",
    certification: "",
    limit: 17,
    page: 1,
  });
  options.search = options.search.trim();
  const [downloading, setDownloading] = useState(false);

  // !OPTIONS QUERIES
  const options_queries = `search=${options.search}&folder=${folderParams}&audio=${audioParams}&available=${availableParams}&vote_average=${vote_averageParams}&certification=${certificationParams}&limit=${options.limit}&page=${pageParams}`;

  // !Fetch movies
  const GET_ALL_TITLES = async () => {
    setDownloading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/titles_admin?${options_queries}`, headers(login.token))
      .then(function (res) {
        // const to_json_video_info = res.data.rows.map((i: any) => {
        //   if (i.episodes) {
        //     i.episodes.map((e: any) => {
        //       e.size = JSON.parse(e.video_info)?.format?.size || 0;
        //       return i;
        //     });
        //   }
        //   return i;
        // });
        // res.data.rows = to_json_video_info;
       

        setDownloading(false);
        setMoviesDocs(res.data);
        // console.log(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GET_ALL_TITLES();
  }, [options, pageParams, folderParams, audioParams, availableParams, vote_averageParams, certificationParams]);

  //   if (downloading) return <Spinner position="relative" />;

  return (
    <TitlesPageSt>
      <DashboardTitles count={moviesDocs.count} options={options} setOptions={setOptions} />
      <TableTitles moviesDocs={moviesDocs} options={options} setOptions={setOptions} folderImg="posters/w250" />
      {/*// TODO */}
      {/* <ButtonCreate /> */}
      <Outlet context={["title_admin", GET_ALL_TITLES]} />
    </TitlesPageSt>
  );
}
