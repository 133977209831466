import { Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";
import Error from "./atoms/Error";
import Signin from "./pages/Signin";
const HomeSt = styled.div`
  width: 100vw;
  height: 100vh;
`;
function Home() {
  return (
    <HomeSt>
      <Routes>
        <Route path="/" element={<Navigate to="/home/signin" replace />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </HomeSt>
  );
}

export default Home;
