import styled from "styled-components";
import empty200 from "../../../img/empty200.png";
// !Icons
import DownloadIcon from "../../../icons/DownloadIcon";
import EyeIcon from "../../../icons/EyeIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import Pagination from "../Pagination";
import Spinner from "components_admin/atoms/Spinner";
import TableSt from "styles/components/templates/TableSt";
// const TableApiEpisodesSt = styled(TableSt)`
//   .row {
//     grid-template-columns: calc(45% - 25vw) 55% 12.5vw 12.5vw;
//   }
//   // !Estilos para DESKTOP
//   @media only screen and (min-width: 568px) {
//     .row {
//       grid-template-columns: 7rem calc(50% - 17rem) 20% 15% 15% 10rem;
//     }
//   }
// `;

const TableApiEpisodesSt = styled(TableSt)`
  .row {
    grid-template-columns: 8rem 10rem 10rem calc(90% - 38rem) 10% 5rem 5rem;
  }
  // !Estilos para DESKTOP
  @media only screen and (max-width: 568px) {
    .row {
      grid-template-columns: 8rem calc(50% - 8rem) calc(50% - 3rem) 3rem;
    }
  }
`;
interface props {
  seasonsDocs: any;
  downloading: boolean;
}
export default function TableApiEpisodes(props: props) {
  const navigate = useNavigate();
  const tmdb_uri = "https://www.themoviedb.org/t/p/w220_and_h330_face/";

  if (props.downloading) return <Spinner position="relative" />;
  return (
    <TableApiEpisodesSt>
      <section className="row fixed api">
        <div className="cell cell_header cell_center">Imagen</div>
        <div className="cell cell_header">Temporada</div>
        <div className="cell cell_header cell_center">Episodio</div>
        <div className="cell cell_header mobile_hidden">Título</div>
        <div className="cell cell_header cell_center mobile_hidden">Calificación</div>
      </section>
      {props.seasonsDocs.map((i: any) => (
        <section
          className="row"
          key={i.id}
          //   onClick={() => navigate(`/admin/content/create/${i.id}`)}
          //   style={{ cursor: "pointer" }}
        >
          <img
            className=" cell cell_thumbnail"
            src={!i.still_path ? empty200 : `${i.still_path}`}
            alt=""
            loading="lazy"
          />
          <div className="cell">Temporada {i.season_number}</div>
          <div className="cell cell_center">Episodio {i.episode_number}</div>
          <div className="cell mobile_hidden">{i.name}</div>
          <div className="cell cell_center mobile_hidden">{i.vote_average}</div>

          <DownloadIcon className="icon" onClick={() => navigate(`download-episode/${i.episode_number}`)} />
        </section>
      ))}
    </TableApiEpisodesSt>
  );
}
