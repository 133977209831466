import { Routes, Route, Navigate } from "react-router-dom";
// import { selectLogin } from "features/login/loginSlice";
// import { useAppSelector } from "app/hooks";
import styled from "styled-components";
// !component pages
import Navbar from "./organisms/Navbar";
import Delete from "./organisms/Delete";
import Users from "./pages/Users";

// import Seasons from "./pages/Seasons";
// import DOWNLOAD_SEASON from "./organisms/season/DOWNLOAD_SEASON";
// import POST_SEASON from "./organisms/season/POST_SEASON";
// import PUT_SEASON from "./organisms/season/PUT_SEASON";

import Titles from "./pages/Titles";
import DOWNLOAD_TITLE_MOVIE from "./organisms/title/DOWNLOAD_TITLE_MOVIE";
import DOWNLOAD_TITLE_TV from "./organisms/title/DOWNLOAD_TITLE_TV";
import POST_TITLE from "./organisms/title/POST_TITLE";
import PUT_TITLE from "./organisms/title/PUT_TITLE";

import ApiSeasons from "./pages/ApiSeasons";
import Api from "./pages/Api";
import ApiEpisodes from "./pages/ApiEpisodes";
import DOWNLOAD_EPISODE from "./organisms/episode/DOWNLOAD_EPISODE";
import Episodes from "./pages/Episodes";
import POST_EPISODE from "./organisms/episode/POST_EPISODE";
import PUT_EPISODE from "./organisms/episode/PUT_EPISODE";
import Subscription from "./organisms/Subscription";
import POST_USER from "./organisms/user/POST_USER";
import PUT_USER from "./organisms/user/PUT_USER";
import Messages from "./pages/Messages";
import PUT_MESSAGE from "./organisms/message/PUT_MESSAGE";
import Subscriptions from "./pages/Subscriptions";
import POST_SUBSCRIPTION from "./organisms/subscription/POST_SUBSCRIPTION";
import PUT_SUBSCRIPTION from "./organisms/subscription/PUT_SUBSCRIPTION";
import Error from "components/atoms/Error";
import PUT_TITLE_IMAGES from "./organisms/title/PUT_TITLE_IMAGES";
import Guests from "./pages/Guests";
import BlazorUsers from "./pages/BlazorUsers";
import POST_BLAZOR_USER from "./organisms/blazor_user/POST_BLAZOR_USER";
import PUT_BLAZOR_USER from "./organisms/blazor_user/PUT_BLAZOR_USER";
import POST_CODE from "./organisms/code/POST_CODE";
import DELETE_CODE from "./organisms/code/DELETE_CODE";
import ALL_CODES from "./organisms/code/ALL_CODES";

const AdminSt = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 8rem calc(100% - 8rem);
  grid-template-rows: 100%;

  // !Estilos para MOBILE
  @media only screen and (max-width: 568px) {
    grid-template-columns: 100%;
    grid-template-rows: 4rem calc(100% - 4rem);
  }
`;
function Admin() {
  //   const login = useAppSelector(selectLogin);

  // !Permissions
  //   const ADMIN = login.role && login.role === "admin" ? true : false;
  //   const ALL = login.role === "admin" || login.role === "employee" ? true : false;

  return (
    <AdminSt>
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate to="/admin/titles" replace />} />

        <Route path="/users" element={<Users />}>
          <Route path="create" element={<POST_USER />} />
          <Route path="update/:id" element={<PUT_USER />} />
          <Route path="delete/:id" element={<Delete />} />
          <Route path="subscription/:user_id" element={<Subscription />}>
            <Route path="delete/:id" element={<Delete />} />
          </Route>
          <Route path="delete/:id" element={<Delete />} />
        </Route>

        <Route path="/codes" element={<ALL_CODES />}>
          <Route path="create" element={<POST_CODE />} />
          <Route path="delete/:codeId" element={<DELETE_CODE />} />
        </Route>

        <Route path="/blazor_users" element={<BlazorUsers />}>
          <Route path="create" element={<POST_BLAZOR_USER />} />
          <Route path="update/:id" element={<PUT_BLAZOR_USER />} />
          <Route path="delete/:id" element={<Delete />} />
        </Route>

        <Route path="/titles" element={<Titles />}>
          <Route path="create" element={<POST_TITLE />} />
          <Route path="update/:id" element={<PUT_TITLE />} />
          <Route path="update_images/:id" element={<PUT_TITLE_IMAGES />} />
          <Route path="delete/:id" element={<Delete />} />
        </Route>

        <Route path="/subscriptions" element={<Subscriptions />}>
          <Route path="create" element={<POST_SUBSCRIPTION />} />
          <Route path="update/:id" element={<PUT_SUBSCRIPTION />} />
          <Route path="delete/:id" element={<Delete />} />
        </Route>

        <Route path="/episodes/:tvId/:tmdb_id" element={<Episodes />}>
          <Route path="create" element={<POST_EPISODE />} />
          <Route path="update/:id" element={<PUT_EPISODE />} />
          <Route path="delete/:id" element={<Delete />} />
        </Route>

        <Route path="/api" element={<Api />}>
          <Route path="download-movie/:id" element={<DOWNLOAD_TITLE_MOVIE />} />
          <Route path="download-tv/:id" element={<DOWNLOAD_TITLE_TV />} />
        </Route>

        <Route path="/api-season/:tvId/:tmdb_id" element={<ApiSeasons />} />

        <Route path="/api-episodes/:tvId/:tmdb_id/:season_number" element={<ApiEpisodes />}>
          <Route path="download-episode/:episode_number" element={<DOWNLOAD_EPISODE />} />
        </Route>

        <Route path="/messages" element={<Messages />}>
          <Route path="update/:id" element={<PUT_MESSAGE />} />
          <Route path="delete/:id" element={<Delete />} />
        </Route>

        <Route path="/guests" element={<Guests />}>
          <Route path="delete/:id" element={<Delete />} />
        </Route>

        <Route path="*" element={<Error />} />
      </Routes>
    </AdminSt>
  );
}

export default Admin;
