
export function tvCertificationToMovieCertificacion(value: string) {
  switch (value) {
    case "TV-Y":
      return "G";
    case "TV-Y7":
      return "G";
    case "TV-G":
      return "PG";
    case "TV-PG":
      return "PG-13";
    case "TV-14":
      return "R";
    case "TV-MA":
      return "NC-17";
    default:
      return "NR";
  }
}
