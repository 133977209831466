import axios from "axios";
import { v4 as uuidv4 } from "uuid";
export const updateMovie = async (
  movie_id: string,
  api_key: string,
  token: string,
  state: any,
  setState: any,
  setSpinner: any
) => {
  setSpinner(true);
  let template = state;
  await axios
    .get(`https://api.themoviedb.org/3/movie/${movie_id}?api_key=${api_key}&language=es-MX`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      template = {
        ...template,
        title: res.data.title,
        original_title: res.data.original_title,
        original_language: res.data.original_language,
        last_update: new Date(res.data.release_date).getTime(),
        date: res.data.release_date,
        runtime: res.data.runtime,
        vote_average: res.data.vote_average,
        genres: res.data.genres.map((i: any) => i.name).join(", "),
        production_companies: res.data.production_companies.map((i: any) => i.name).join(", "),
        production_countries: res.data.production_countries.map((i: any) => i.name).join(", "),
        tagline: res.data.tagline,
        overview: res.data.overview,
        budget: res.data.budget,
        revenue: res.data.revenue,
        tmdb_id: res.data.id,
        collection_id: res.data.belongs_to_collection?.id || 0,
        imdb_id: res.data.imdb_id || uuidv4(),
        type: "movie",
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  // !GET TITLE ENGLISH
  await axios
    .get(`https://api.themoviedb.org/3/movie/${movie_id}?api_key=${api_key}&language=en-US`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      template = {
        ...template,
        title_en: res.data.title,
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  // !Credits for CAST & DIRECTOR
  await axios
    .get(`https://api.themoviedb.org/3/movie/${movie_id}/credits?api_key=${api_key}&language=es-MX`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      // console.log(res.data);
      template = {
        ...template,
        cast: res.data.cast.map((i: any) => i.name).join(", "),
        director: res.data.crew.find(({ job }: any) => job.toLowerCase() === "director")?.name || "",
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  // !Keywords
  await axios
    .get(`https://api.themoviedb.org/3/movie/${movie_id}/keywords?api_key=${api_key}&language=es-MX`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      template = {
        ...template,
        keywords: res.data.keywords.map((i: any) => i.name).join(", "),
      };
      // console.log(res.data);

      //       setState(template);
    })
    .catch(function (error) {
      console.log(error);
    });
  // !Release dates
  await axios
    .get(`https://api.themoviedb.org/3/movie/${movie_id}/release_dates?api_key=${api_key}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      // console.log(res.data.results.find((i: any) => i.iso_3166_1.toLowerCase() === "us")?.release_dates);
      const us = res.data.results.find((i: any) => i.iso_3166_1.toLowerCase() === "us"); // return value OR undefined
      const release_dates =
        us === undefined ? undefined : us.release_dates.find((i: any) => i.certification !== "")?.certification;
      const certification = release_dates === undefined ? "NR" : release_dates;
      // console.log(certification);
      template = {
        ...template,
        certification: certification,
      };
      //       setState(template);
    })
    .catch(function (error) {
      console.log(error);
    });
  // !OTHER TITLES
  await axios
    .get(`https://api.themoviedb.org/3/movie/${movie_id}/translations?api_key=${api_key}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      const title_es = res.data.translations.find((i: any) => i.iso_639_1 === "es").data.title || "";
      //       const title_en = res.data.translations.find((i: any) => i.iso_639_1 === "en").data.title || "";
      const tagline_es = res.data.translations.find((i: any) => i.iso_639_1 === "es").data.tagline || "";
      const overview_es = res.data.translations.find((i: any) => i.iso_639_1 === "es").data.overview || "";

      template = {
        ...template,
        title_es: title_es,
        // title_en: title_en,
        tagline_es: tagline_es,
        overview_es: overview_es,
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  // !ALTERNATIVE TITLES ---> data_games
  //   await axios
  //     .get(`https://api.themoviedb.org/3/movie/${movie_id}/alternative_titles?api_key=${api_key}`, {
  //       headers: {
  //         authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then(function (res) {
  //       template = {
  //         ...template,
  //         data_game: { ...template.data_game, alternative_titles: res.data.titles },
  //       };
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // !SIMILAR_TITLES es-MX ---> data_games
  await axios
    .get(`https://api.themoviedb.org/3/movie/${movie_id}/similar?api_key=${api_key}&language=es-MX&page=1`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      const similar_titles_es: any = [];
      res.data.results.map((i: any) => {
        if (i.overview !== "") {
          similar_titles_es.push({ id: i.id, title: i.title });
        }
      });
      template = {
        ...template,
        data_game: { ...template.data_game, similar_titles_es: similar_titles_es },
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  // !SIMILAR_TITLES en-US ---> data_games
  await axios
    .get(`https://api.themoviedb.org/3/movie/${movie_id}/similar?api_key=${api_key}&language=en-US&page=1`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      const similar_titles_en: any = [];
      res.data.results.map((i: any) => {
        if (i.overview !== "") {
          similar_titles_en.push({ id: i.id, title: i.title });
        }
      });
      template = {
        ...template,
        data_game: { ...template.data_game, similar_titles_en: similar_titles_en },
      };
    })
    .catch(function (error) {
      console.log(error);
    });
  // !GET IMAGES
  await axios
    .get(`https://api.themoviedb.org/3/movie/${movie_id}/images?api_key=${api_key}`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(function (res) {
      // !Backdrops
      let backdrops: any = [];
      backdrops = [...backdrops, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === null)];
      backdrops = [...backdrops, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "es")];
      backdrops = [...backdrops, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "en")];
      if (template.original_language !== "en" && template.original_language !== "en") {
        backdrops = [
          ...backdrops,
          ...res.data.backdrops.filter((i: any) => i.iso_639_1 === template.original_language),
        ];
      }
      // !Backdrops PORTRAIT
      let backdrops_portrait: any = [];
      backdrops_portrait = [...backdrops_portrait, ...res.data.posters.filter((i: any) => i.iso_639_1 === null)];
      backdrops_portrait = [...backdrops_portrait, ...res.data.posters.filter((i: any) => i.iso_639_1 === "es")];
      backdrops_portrait = [...backdrops_portrait, ...res.data.posters.filter((i: any) => i.iso_639_1 === "en")];
      if (template.original_language !== "en" && template.original_language !== "en") {
        backdrops_portrait = [
          ...backdrops_portrait,
          ...res.data.posters.filter((i: any) => i.iso_639_1 === template.original_language),
        ];
      }
      // *set backdrops_portrait
      template = {
        ...template,
        data_game: { ...template.data_game, backdrops_portrait },
      };

      // !Logos
      let logos: any = [];
      logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === "es")];
      logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === "en")];
      logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === null)];
      if (template.original_language !== "en" && template.original_language !== "en") {
        logos = [...logos, ...res.data.logos.filter((i: any) => i.iso_639_1 === template.original_language)];
      }

      // !Posters
      let posters: any = [];
      posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === "es")];
      posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === "en")];
      if (template.original_language !== "en" && template.original_language !== "en") {
        posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === template.original_language)];
      }
      posters = [...posters, ...res.data.posters.filter((i: any) => i.iso_639_1 === null)];

      template = {
        ...template,
        backdrops: backdrops,
        backdrops_portrait: backdrops_portrait,
        posters: posters,
        logos: logos,
      };
      // !Posters Landscape
      let posters_landscape: any = [];
      posters_landscape = [...posters_landscape, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "es")];
      posters_landscape = [...posters_landscape, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === "en")];
      if (template.original_language !== "en" && template.original_language !== "en") {
        posters_landscape = [
          ...posters_landscape,
          ...res.data.backdrops.filter((i: any) => i.iso_639_1 === template.original_language),
        ];
      }
      posters_landscape = [...posters_landscape, ...res.data.backdrops.filter((i: any) => i.iso_639_1 === null)];

      //       template = {
      //         ...template,
      //         backdrops: backdrops,
      //         backdrops_portrait: backdrops_portrait,
      //         posters: posters,
      //         logos: logos,
      //         posters_landscape: posters_landscape,
      //       };
      setState(template);
      //       console.log(template);
      setSpinner(false);
      //       BlobImage(tmdb_uri_img_blob, backdrops[0]?.file_path, setBackdrop);
      //       BlobImage(tmdb_uri_img_blob, backdrops_portrait[0]?.file_path, setBackdropPortrait);
      //       BlobImage(tmdb_uri_img_blob, logos[0]?.file_path, setLogo);
      //       BlobImage(tmdb_uri_img_blob, posters_landscape[0]?.file_path, setPosterLandscape);
      //       BlobImage(tmdb_uri_img_blob, posters[0]?.file_path, setPoster);
    })
    .catch(function (error) {
      console.log(error);
    });
};
