import styled from "styled-components";
import empty200 from "../../../img/empty200.png";
// !Icons
import EyeIcon from "icons/EyeIcon";
import EditIcon from "../../../icons/EditIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { FaFolder } from "react-icons/fa";
import Pagination from "components_admin/atoms/Pagination";
import TableSt from "styles/components/templates/TableSt";
const TableTitlesSt = styled(TableSt)`
  .row {
    grid-template-columns: 6rem calc(35% - 10rem) 10% calc(55% - 11rem) 5rem 5rem 5rem;
  }
  // !Estilos para DESKTOP
  @media only screen and (max-width: 568px) {
    .row {
      grid-template-columns: 6rem calc(100% - 15rem) 3rem 3rem 3rem;
    }
  }
`;

interface props {
  messagesDocs: any;
  folderImg: string;
}
export default function TableMessages(props: props) {
  const navigate = useNavigate();
  return (
    <TableTitlesSt>
      <section className="row fixed">
        <div className="cell cell_header cell_center">Imagen</div>
        <div className="cell cell_header">Título</div>
        <div className="cell cell_header mobile_hidden">Usuario</div>
        <div className="cell cell_header mobile_hidden">Mensaje</div>
      </section>

      {props.messagesDocs.rows.map((i: any) => (
        <section className="row" key={i.id}>
          <img
            className="cell cell_thumbnail"
            src={
              i.poster_path === ""
                ? empty200
                : `${process.env.REACT_APP_STATIC_BUCKET}/${props.folderImg}/${i.poster_path}`
            }
            alt=""
          />
          <div className="cell">{i.title}</div>
          <div className="cell mobile_hidden">{i.user_name}</div>
          <div className="cell mobile_hidden">{i.message}</div>

          <EyeIcon className="icon" onClick={() => navigate(`update/${i.id}`)} />
          <EditIcon className="icon" onClick={() => navigate(`/admin/titles/update/${i.title_id}`)} />
          <DeleteIcon className="icon" onClick={() => navigate(`delete/${i.id}`)} />
        </section>
      ))}
      {/* <Pagination count={props.moviesDocs.count} options={props.options} setOptions={props.setOptions} /> */}
    </TableTitlesSt>
  );
}
