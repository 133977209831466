import { Outlet, useLocation } from "react-router-dom";
import styled from "styled-components";
import Spinner from "../atoms/Spinner";
import ButtonCreate from "../atoms/ButtonCreate";
import axios from "axios";
import { useEffect, useState } from "react";
import headers from "libs/headers";
import { useAppSelector } from "app/hooks";
import { selectLogin } from "features/login/loginSlice";

import TableUsers from "components_admin/molecules/tables/TableUsers";
import DashboardUsers from "components_admin/molecules/dashboards/DashboardUsers";
import DashboardBlazorUsers from "components_admin/molecules/dashboards/DashboardBlazorUsers";
import TableBlazorUsers from "components_admin/molecules/tables/TableBlazorUsers";
const BlazorUsersPageSt = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 7.5rem calc(100% - 7.5rem);
`;

export default function BlazorUsers() {
  const login = useAppSelector(selectLogin);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // !location_params
  const pageParams = params.get("page") ? params.get("page") : "1";

  // !State
  const [moviesDocs, setMoviesDocs] = useState({
    rows: [],
    count: 0,
  });

  const [options, setOptions] = useState({
    search: "",
    limit: 17,
  });
  const [downloading, setDownloading] = useState(false);

  // !OPTIONS QUERIES
  const options_queries = `search=${options.search}&limit=${options.limit}&page=${pageParams}`;

  // !Fetch movies
  const GET_ALL_TITLES = async () => {
    setDownloading(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/blazor_users_admin?${options_queries}`, headers(login.token))
      .then(function (res) {
        setDownloading(false);
        setMoviesDocs(res.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GET_ALL_TITLES();
  }, [options, pageParams]);

  if (downloading) return <Spinner position="relative" />;

  return (
    <BlazorUsersPageSt>
      <DashboardBlazorUsers count={moviesDocs.count} options={options} setOptions={setOptions} />
      <TableBlazorUsers moviesDocs={moviesDocs} options={options} setOptions={setOptions} folderImg="posters/w250" />
      <ButtonCreate />
      <Outlet context={["api/v1/blazor_user_admin", GET_ALL_TITLES]} />
    </BlazorUsersPageSt>
  );
}
