import { useAppSelector } from "../../../app/hooks";
import Spinner from "../../atoms/Spinner";
import useCrudMovie from "../../../hooks/useCrudMovie";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import TemplateSubscription from "./TemplateSubscription";
import useSubscription from "./useSubscription";
import useCrudSubcription from "hooks/useCrudSubscription";
import { log } from "console";

export default function PUT_SUBSCRIPTION() {
  const params = useParams();
  // !Context OUTLET
  const [URI, fetchEmployees] = useOutletContext<any>();

  // !Hooks
  const { state, setState, handleChange, formData, backdrop, setBackdrop, poster, setPoster } = useSubscription();
  const { GET, PUT_FORM_DATA, downloading, uploading } = useCrudSubcription();

  // !Handle SUBMIT POST
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    PUT_FORM_DATA(URI, `${params.id}`, formData, fetchEmployees);
  };

  // !Params
  const movie_id = params.id || "";
  useEffect(() => {
    GET(URI, `${movie_id}`, setState, setBackdrop, setPoster); //TODO
  }, []);

  if (downloading) {
    return <Spinner position="absolute" />;
  }
  if (uploading) {
    return <Spinner position="absolute" />;
  }
  return (
    <TemplateSubscription
      titleText="Editar suscripción"
      buttonText="Editar suscripción"
      titleImg="Imagen"
      state={state}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      readMode={false}
      backdrop={backdrop}
      setBackdrop={setBackdrop}
      poster={poster}
      setPoster={setPoster}
    />
  );
}
