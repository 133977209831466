import ImagePreview from "../ImagePreview";
import InputForm from "components_admin/molecules/inputs/InputForm";
import TextareaForm from "components_admin/molecules/inputs/TextareaForm";
import SelectForm from "components_admin/molecules/inputs/SelectForm";
import FormSt from "styles/components/templates/FormSt";
import ScrollSt from "styles/components/templates/ScrollSt";
import styled from "styled-components";
const ContainerFormSt = styled(ScrollSt)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  padding: 2rem 0;
  background: black;
  @media only screen and (max-width: 568px) {
    padding: 0rem 0rem;
  }
`;
interface props {
  titleText: string;
  buttonText: string;
  titleImg: string;
  state: any;
  handleChange: any;
  handleSubmit: any;
  readMode: boolean;

  thumbnail: any;
  setThumbnail: any;
}
export default function TemplateEpisode(props: props) {
  console.log(props.state);

  return (
    <ContainerFormSt>
      <FormSt onSubmit={(e) => props.handleSubmit(e)}>
        <p className="form_title"> {props.titleText}</p>
        <div className="form_line"></div>
        {/* <ImagePreview file={props.backdrop} setFile={props.setBackdrop} /> */}
        <ImagePreview file={props.thumbnail} setFile={props.setThumbnail} />
        {/* <InputForm
          label="Título"
          type="text"
          name="name"
          value={props.state.name}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Título ESPAÑA"
          type="text"
          name="name_es"
          value={props.state.name_es}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Fecha de estreno"
          type="text"
          name="air_date"
          value={props.state.air_date}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        /> */}
        <InputForm
          label="Temporada"
          type="number"
          name="season_number"
          value={props.state.season_number}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Episodio"
          type="number"
          name="episode_number"
          value={props.state.episode_number}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        {/* // !borrar */}
        <InputForm
          label="Link"
          type="text"
          name="link"
          value={props.state.link}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <button
          className="form_button"
          type="submit"
          style={!props.thumbnail ? { background: "red" } : { background: "#6200ff" }}
        >
          {props.buttonText}
        </button>
        <TextareaForm
          label="Sinopsis"
          name="overview"
          value={props.state.overview}
          onChange={props.handleChange}
          required={false}
        />
        <TextareaForm
          label="Sinopsis  ESPAÑA"
          name="overview_es"
          value={props.state.overview_es}
          onChange={props.handleChange}
          required={false}
        />
        <InputForm
          label="Duración"
          type="number"
          name="runtime"
          value={props.state.runtime}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />{" "}
        <InputForm
          label="Calificación"
          type="number"
          name="vote_average"
          value={props.state.vote_average}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        {/* // !Admin */}
        <InputForm
          label="INTRO inicio"
          type="number"
          name="start_intro"
          value={props.state.start_intro}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="INTRO final"
          type="number"
          name="end_intro"
          value={props.state.end_intro}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <InputForm
          label="Link"
          type="text"
          name="link"
          value={props.state.link}
          onChange={props.handleChange}
          required={false}
          readOnly={false}
        />
        <SelectForm
          label="Resolución"
          name="resolution"
          value={props.state.resolution}
          onChange={props.handleChange}
          options={[
            { value: "1080", name: "1080" },
            { value: "1000", name: "Mejorado" },
            { value: "720", name: "720" },
            { value: "480", name: "480" },
          ]}
          required={true}
        />
        <SelectForm
          label="Audio"
          name="audio"
          value={props.state.audio}
          onChange={props.handleChange}
          options={[
            { value: "multilenguaje", name: "Multilenguaje" },
            { value: "latino", name: "Latino" },
            { value: "castellano", name: "Castellano" },
            { value: "subtitulado", name: "Subtitulado" },
          ]}
          required={true}
        />
        <SelectForm
          label="Disponible"
          name="available"
          value={props.state.available}
          onChange={props.handleChange}
          options={[
            { value: "false", name: "No" },
            { value: "true", name: "Si" },
          ]}
          required={true}
        />
        {/* {props.state.id !== 0 && ( */}
        <button
          className="form_button"
          type="submit"
          style={!props.thumbnail ? { background: "red" } : { background: "#6200ff" }}
        >
          {props.buttonText}
        </button>
      </FormSt>
    </ContainerFormSt>
  );
}
