import styled from "styled-components";
import empty200 from "../../../img/empty200.png";
// !Icons
import { FaFolder } from "react-icons/fa";
import DownloadIcon from "../../../icons/DownloadIcon";
import EditIcon from "../../../icons/EditIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import TableSt from "styles/components/templates/TableSt";

const TableSeasonsSt = styled(TableSt)`
  .row {
    grid-template-columns: 8rem 5rem 5rem calc(100% - 44rem) 8rem 8rem 5rem 5rem;
  }
  // !Estilos para DESKTOP
  @media only screen and (max-width: 568px) {
    .row {
      grid-template-columns: 8rem calc(50% - 10rem) calc(50% - 10rem) 6rem 3rem 3rem;
    }
  }
`;

interface props {
  state: any;
  folderImg: string;
}
export default function TableSeasons(props: props) {
  const navigate = useNavigate();

  return (
    <TableSeasonsSt>
      <section className="row fixed">
        <div className="cell cell_header cell_center">{props.state.count}</div>
        <div className="cell cell_header">S</div>
        <div className="cell cell_header">E</div>
        <div className="cell cell_header mobile_hidden">Link</div>
        <div className="cell cell_header cell_center">Peso</div>
        <div className="cell cell_header cell_center">Disponible</div>
      </section>

      {props.state.rows.map((i: any, index: number) => (
        <section className="row" key={i.id}>
          <img
            className=" cell cell_thumbnail"
            src={
              i.thumbnail_path === ""
                ? empty200
                : `${process.env.REACT_APP_STATIC_BUCKET}/${props.folderImg}/${i.thumbnail_path}`
            }
            alt=""
            style={{ padding: 0 }}
            loading="lazy"
          />
          <div className="cell">S{i.season_number}</div>
          <div className="cell">E{i.episode_number}</div>
          <div className="cell mobile_hidden" style={i.available ? { color: "#00ffbb" } : { color: "#ff0059" }}>
            {decodeURIComponent(i.link.replace("https://f002.backblazeb2.com/file/", ""))}
          </div>
          <div className="cell cell_center" style={i.size !== 0 ? { color: "#00ffbb" } : { color: "#ff0059" }}>{`${(
            i.video_info?.format?.size / 1073741824
          ).toFixed(2)} Gb`}</div>

          <div
            className="cell mobile_hidden cell_center"
            style={i.available ? { color: "#00ffbb" } : { color: "#ff0059" }}
          >
            {i.available ? "Si" : "No"}
          </div>

          <EditIcon className="icon" onClick={() => navigate(`update/${i.id}`)} />
          <DeleteIcon className="icon" onClick={() => navigate(`delete/${i.id}`)} />
        </section>
      ))}
    </TableSeasonsSt>
  );
}
