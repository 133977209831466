import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { useEffect } from "react";
import TableApiSeasons from "components_admin/molecules/tables/TableApiSeasons";
const ApiSeasonsPageSt = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 100%;
`;
export default function ApiSeasons() {
  const params = useParams();
  const [seasonsDocs, setSeasonsDocs] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const api_key = "eafee52978a6fa33af7118d81011116b";
  const token =
    "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJlYWZlZTUyOTc4YTZmYTMzYWY3MTE4ZDgxMDExMTE2YiIsInN1YiI6IjYzMjY4MzdiNjdiNjEzMDA3Yjg5MzA1MSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.DkYIhC6xJOI2qoaHyD1OVOXVBcZRKaeDXm6In9d0srM";

  // !First fetch set page 1
  const fetchSeasons = async () => {
    //     setDownloading(true);
    await axios
      .get(`https://api.themoviedb.org/3/tv/${params.tmdb_id}?api_key=${api_key}&language=es-MX`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .then(function (res) {
        // !Setting poster
        res.data.seasons.map((i: any) => {
          i.poster_path = `${process.env.REACT_APP_TMDB_POSTER_220X300}/${i.poster_path}`;
          return i;
        });
        setSeasonsDocs(res.data.seasons);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // !Rerender on type change
  useEffect(() => {
    fetchSeasons();
  }, []);

  return (
    <ApiSeasonsPageSt>
      {/* <DashboardApiSeasons /> */}
      <TableApiSeasons seasonsDocs={seasonsDocs} downloading={downloading} />
      <Outlet context={["season"]} />
    </ApiSeasonsPageSt>
  );
}
