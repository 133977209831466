import axios from "axios";
import useLogin from "hooks/useLogin";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import FormSt from "styles/components/templates/FormSt";
import { SpinnerCircularFixed } from "spinners-react";
const SigninSt = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: start center;
  overflow-y: scroll;
`;

export default function Signin() {
  const navigate = useNavigate();
  // !States
  const [state, setState] = useState({
    user_name: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // !HOOKS
  const { LOGIN } = useLogin();

  // !Functions
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    setState({ ...state, [name]: value.trim() });
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    // !Create new client
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/signin_admin/`, state)
      .then(function (res) {
        if (res.status === 200) {
          LOGIN(res.data.token);
          navigate("/admin");
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };
  return (
    <SigninSt>
      <FormSt onSubmit={onSubmit}>
        <h1 className="form_title">Iniciar sesión</h1>
        {/* <p className="form_sub_title">Es rápido y fácil.</p> */}
        <div className="form_line"></div>
        <div className="form_container_label_input">
          <label className="label" htmlFor="user">
            Nombre de usuario
          </label>
          <input
            className="input"
            type="text"
            id="user"
            name="user_name"
            value={state.user_name}
            onChange={handleChange}
            placeholder=""
            required
          />
        </div>
        <div className="form_container_label_input">
          <label className="label" htmlFor="user">
            Contraseña
          </label>
          <input
            className="input"
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            value={state.password}
            onChange={handleChange}
            placeholder=""
            required
          />
        </div>
        <div className="form_container_checkbox_label">
          <input
            className="form_container_checkbox_label__checkbox"
            id="showPassword"
            type="checkbox"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
          <label className="form_container_checkbox_label__label" htmlFor="showPassword">
            Mostrar contraseña
          </label>
        </div>
        <div className="form_line"></div>
        <button className="form_button" type="submit" disabled={loading && true}>
          Ingresar
          {loading && (
            <SpinnerCircularFixed
              className="spinner"
              size={"1.5rem"}
              thickness={159}
              speed={100}
              color="#ebebeb"
              secondaryColor="rgba(255, 255, 255, 0.44)"
            />
          )}
        </button>
      </FormSt>
    </SigninSt>
  );
}
