import styled from "styled-components";
import empty200 from "../../../img/empty200.png";
// !Icons
import EditIcon from "../../../icons/EditIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { FaFolder } from "react-icons/fa";
import Pagination from "components_admin/atoms/Pagination";
import TableSt from "styles/components/templates/TableSt";
import { parseDate } from "libs/parseDate";
const TableSubscriptionsSt = styled(TableSt)`
  .row {
    grid-template-columns: 6rem calc(40% - 16rem) 15% 15% 15% 15% 5rem 5rem;
  }
  // !Estilos para DESKTOP
  @media only screen and (max-width: 568px) {
    .row {
      grid-template-columns: 6rem calc(70% - 12rem) 30% 3rem 3rem;
    }
  }
`;

interface props {
  moviesDocs: any;
  options: any;
  setOptions: any;
  folderImg: string;
}
export default function TableSubscriptions(props: props) {
  const navigate = useNavigate();
  return (
    <TableSubscriptionsSt>
      <section className="row fixed">
        <div className="cell cell_header cell_center">Imagen</div>
        <div className="cell cell_header">Usuario</div>
        <div className="cell cell_header mobile_hidden">Inicio</div>
        <div className="cell cell_header mobile_hidden">Expira</div>
        <div className="cell cell_header cell_center ">Plan</div>
        <div className="cell cell_header cell_center mobile_hidden">Pantallas</div>
      </section>

      {props.moviesDocs.rows.map((i: any) => (
        <section className="row" key={i.id}>
          <img className="cell cell_thumbnail" src={empty200} alt="" />
          <div className="cell">{i.user_name}</div>
          <div className="cell mobile_hidden">{parseDate(i.starts)}</div>
          <div className="cell mobile_hidden">{parseDate(i.expiration)}</div>
          <div className="cell cell_center ">{i.plan}</div>
          <div className="cell cell_center mobile_hidden">{i.screens}</div>

          <EditIcon className="icon" onClick={() => navigate(`update/${i.id}`)} />
          <DeleteIcon className="icon" onClick={() => navigate(`delete/${i.id}`)} />
        </section>
      ))}
      <Pagination count={props.moviesDocs.count} options={props.options} setOptions={props.setOptions} />
    </TableSubscriptionsSt>
  );
}
