import { useAppSelector } from "../app/hooks";
import axios from "axios";
import { selectLogin } from "../features/login/loginSlice";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import headers from "libs/headers";
import { BlobImage } from "../libs/BlobImage";
import toast from "react-hot-toast";

export default function useCrudSubcription() {
  const navigate = useNavigate();

  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const login = useAppSelector(selectLogin);

  // !Handle DOWNLOAD_POST_FORM_DATA
  const DOWNLOAD_POST_FORM_DATA = async (uri: string, formData: object) => {
    setUploading(true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/${uri}`, formData, headers(login.token))
      .then(function (res) {
        setUploading(false);
        navigate(-1);
        toast.success("Saved!");
      })
      .catch(function (error) {
        setUploading(false);
      });
  };

  // !Handle POST_FORM_DATA
  const POST_FORM_DATA = async (uri: string, formData: object, reloadBack: () => {}) => {
    setUploading(true);
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/${uri}`, formData, headers(login.token))
      .then(function (res) {
        setUploading(false);
        reloadBack();
        navigate(-1);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };

  // !Handle GET
  const GET = async (uri: string, id: string | null, setState: any, setBackdrop: any, setPoster: any) => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/${uri}/${id}`, headers(login.token)) //TODO organizar rutas backend
      .then(function (res) {
        setDownloading(false);
        setState(res.data);
        BlobImage(`${process.env.REACT_APP_STATIC_BUCKET}/backdrops/w1000`, res.data.backdrop_path, setBackdrop);
        BlobImage(`${process.env.REACT_APP_STATIC_BUCKET}/posters/w500`, res.data.poster_path, setPoster);
      })
      .catch(function (error) {
        setDownloading(false);
        console.log(error);
      });
  };

  // !Handle PUT_FORM_DATA
  const PUT_FORM_DATA = async (uri: string, id: string, data: object, reloadBack: () => {}) => {
    setUploading(true);
    await axios
      .put(`${process.env.REACT_APP_BACKEND_URL}/${uri}/${id}`, data, headers(login.token))
      .then(function (res) {
        setUploading(false);
        reloadBack();
        navigate(-1);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };
  // !Handle DELETE
  const DELETE = async (uri: string, id: string, reloadBack: () => {}) => {
    setUploading(true);
    await axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/${uri}/${id}`, headers(login.token))
      .then((res) => {
        // setUploading(false);
        reloadBack();
        navigate(-1);
        toast.success("Borrado");
        // console.log(res);
      })
      .catch(function (error) {
        setUploading(false);
      });
  };

  //   // !Fetching all docs
  //   const GET_ALL_MOVIES = async (uri: string, movies: any, setMovies: any, page: string) => {
  //     setDownloading(true);
  //     await axios
  //       .get(`${process.env.REACT_APP_BACKEND_URL}/${uri}?page=${page}`, headers(login.token))
  //       .then(function (res) {
  //         console.log(res.data);

  //         setDownloading(false);
  //         setMovies([...movies, ...res.data.rows]);
  //       })
  //       .catch(function (error) {
  //         console.log(error, "aaaaaa");

  //         checkErrors(error);
  //       });
  //   };
  return {
    DOWNLOAD_POST_FORM_DATA,
    POST_FORM_DATA,
    GET,
    PUT_FORM_DATA,
    DELETE,
    //     GET_ALL_MOVIES,
    downloading,
    uploading,
  };
}
