export const BlobImage = async (tmdb_uri: string, poster_path: string, setFile: any) => {
  poster_path &&
    (await fetch(`${tmdb_uri}/${poster_path}`)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new File([blob], "test", {
            type: blob.type,
          })
      )
      .then((file) => {
        setFile(file);
        //     setPreview(URL.createObjectURL(file));
      }));
};
