import { useAppSelector } from "app/hooks";
import Spinner from "components_admin/atoms/Spinner";
import useCrudMovie from "hooks/useCrudMovie";
import useCrudEpisode from "hooks/useCrudEpisode";
import useCrudTv from "hooks/useCrudTv";
import React, { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import TemplateEpisode from "./TemplateEpisode";
import useEpisode from "./useEpisode";

export default function POST_EPISODE() {
  const params = useParams();
  // !Context OUTLET
  const [URI, fetchEmployees] = useOutletContext<any>();
  const { state, setState, handleChange, formData, thumbnail, setThumbnail } = useEpisode();
  const { POST_FORM_DATA, uploading } = useCrudEpisode();

  // !Handle SUBMIT POST
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    POST_FORM_DATA(URI, formData, fetchEmployees);
  };

  // !Seting tvId to State
  useEffect(() => {
    setState({ ...state, titleId: parseInt(params.tvId || "1") });
  }, []);
  // !uploading data
  if (uploading) {
    return <Spinner position="absolute" />;
  }

  return (
    <TemplateEpisode
      titleText="Crear episodio"
      buttonText="Crear episodio"
      titleImg="Imagen"
      state={state}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      readMode={false}
      thumbnail={thumbnail}
      setThumbnail={setThumbnail}
    />
  );
}
