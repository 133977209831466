import { logDOM } from "@testing-library/react";
import Spinner from "components_admin/atoms/Spinner";
import useCrudUser from "hooks/useCrudUser";
import { random_passowrd } from "libs/RandomPassword";
import React, { useEffect } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import TemplateUser from "./TemplateUser";
import useUser from "./useUser";

export default function POST_USER() {
  const params = useParams();
  // !Context OUTLET
  const [URI, fetchEmployees] = useOutletContext<any>();
  const { state, setState, handleChange, formData, thumbnail, setThumbnail } = useUser();
  const { POST_FORM_DATA, uploading } = useCrudUser();

  // !Handle SUBMIT POST
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    POST_FORM_DATA(URI, formData, fetchEmployees);
  };

  // !Seting tvId to State
  useEffect(() => {
    setState({
      ...state,
      password: random_passowrd(6),
      code: Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000,
    });
  }, []);

  // !uploading data
  if (uploading) {
    return <Spinner position="absolute" />;
  }

  return (
    <TemplateUser
      titleText="Crear usuario"
      buttonText="Crear usuario"
      titleImg="Imagen"
      state={state}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      readMode={false}
      thumbnail={thumbnail}
      setThumbnail={setThumbnail}
    />
  );
}
