import styled from "styled-components";
import empty200 from "../../../img/empty200.png";
// !Icons
import EditIcon from "../../../icons/EditIcon";
import DeleteIcon from "../../../icons/DeleteIcon";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { FaFolder } from "react-icons/fa";
import Pagination from "components_admin/atoms/Pagination";
import TableSt from "styles/components/templates/TableSt";
const TableBlazorUsersSt = styled(TableSt)`
  .row {
    grid-template-columns: 6rem calc(50% - 16rem) 10% 10% 10% 10% 10% 5rem 5rem;
  }
  // !Estilos para DESKTOP
  @media only screen and (max-width: 568px) {
    .row {
      grid-template-columns: 6rem calc(75% - 12rem) 25% 3rem 3rem;
    }
  }
`;

interface props {
  moviesDocs: any;
  options: any;
  setOptions: any;
  folderImg: string;
}
export default function TableBlazorUsers(props: props) {
  const navigate = useNavigate();

  return (
    <TableBlazorUsersSt>
      <section className="row fixed">
        <div className="cell cell_header">Imagen</div>
        <div className="cell cell_header">Usuario</div>
        <div className="cell cell_header mobile_hidden">Nombre</div>
        <div className="cell cell_header mobile_hidden">Apellido</div>
        <div className="cell cell_header cell_center mobile_hidden">Verificado</div>
        <div className="cell cell_header cell_center mobile_hidden">Pais</div>
        <div className="cell cell_header cell_center ">Créditos</div>
      </section>

      {props.moviesDocs.rows.map((i: any) => (
        <section className="row" key={i.id}>
          <img className="cell cell_thumbnail" src={empty200} alt="" />
          <div className="cell">{i.user_name}</div>
          <div className="cell mobile_hidden">{i.name}</div>
          <div className="cell mobile_hidden">{i.last_name}</div>

          <div
            className="cell cell_center mobile_hidden"
            style={i.verified ? { color: "#00ffbb" } : { color: "#ff0059" }}
          >
            {i.verified === true ? "Si" : "No"}
          </div>
          <div className="cell cell_center mobile_hidden">{i.country}</div>

          <div className="cell cell_center " style={i.credits < 1000 ? { color: "#ff0059" } : { color: "#00ffbb" }}>
            {i.credits}
          </div>

          {/* <FaFolder className="icon" onClick={() => navigate(`subscription/${i.id}`)} /> */}
          <EditIcon className="icon" onClick={() => navigate(`update/${i.id}`)} />
          <DeleteIcon className="icon" onClick={() => navigate(`delete/${i.id}`)} />
        </section>
      ))}
      <Pagination count={props.moviesDocs.count} options={props.options} setOptions={props.setOptions} />
    </TableBlazorUsersSt>
  );
}
