import { useNavigate } from "react-router-dom";

import { ModalSt } from "../../../styles/ModalSt";
import { CodeIT } from "./typeCode";
import { SpinnerCircularFixed } from "spinners-react";
import Spinner from "../../../components/atoms/Spinner";
import { useAppSelector } from "../../../app/hooks";
import { selectLogin } from "../../../features/login/loginSlice";
import FormSt from "styles/FormSt";

interface props {
  state: CodeIT;
  handleChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  title: string;
  uploading: boolean;
  downloading: boolean;
}
const TemplateEvent = (props: props) => {
  const navigate = useNavigate();
  const login = useAppSelector(selectLogin);

  return (
    <ModalSt>
      <FormSt onSubmit={props.onSubmit}>
        <p className="form_title">{props.title}</p>
        <div className="form_line"></div>
        <div className="form_container_label_input">
          <label className="label" htmlFor="user">
            Código
          </label>
          <input
            className="input "
            type="text"
            name="code"
            value={props.state.code}
            onChange={props.handleChange}
            placeholder=""
            required
          />
        </div>
        <div className="form_container_label_input">
          <label className="label" htmlFor="user">
            Nombre de Usuario
          </label>
          <input
            className="input "
            type="text"
            name="username"
            value={props.state.username}
            onChange={props.handleChange}
            placeholder=""
            required
          />
        </div>
        <div className="form_container_label_input">
          <label className="label" htmlFor="user">
            Contraseña
          </label>
          <input
            className="input "
            type="text"
            name="password"
            value={props.state.password}
            onChange={props.handleChange}
            placeholder=""
            required
          />
        </div>
        <div className="form_line"></div>
        <button className="form_button button_white" type="submit" disabled={props.uploading && true}>
          {props.title}
          {props.uploading && (
            <SpinnerCircularFixed
              className="spinner"
              size={"1.5rem"}
              thickness={159}
              speed={100}
              color="#6a6a6a"
              secondaryColor="rgba(69, 68, 68, 0.44)"
            />
          )}
        </button>
        <button className="form_button button_gray" type="button" onClick={() => navigate(-1)}>
          Volver atras
        </button>
      </FormSt>
    </ModalSt>
  );
};

export default TemplateEvent;
