import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { useEffect } from "react";
import DashboardApi from "components_admin/molecules/dashboards/DashboardApi";
import TableApi from "components_admin/molecules/tables/TableApi";
const ApiPageSt = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 7.5rem calc(100% - 7.5rem);

`;
export default function Api() {
  const params = useParams();
  const [type, setType] = useState("movie");
  const [search, setSearch] = useState("");
  const [state, setState] = useState({
    page: 1,
    results: [],
    total_pages: 1,
    total_results: 0,
  });

  const [downloading, setDownloading] = useState(false);

  const api_key = "eafee52978a6fa33af7118d81011116b";
  const token =
    "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJlYWZlZTUyOTc4YTZmYTMzYWY3MTE4ZDgxMDExMTE2YiIsInN1YiI6IjYzMjY4MzdiNjdiNjEzMDA3Yjg5MzA1MSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.DkYIhC6xJOI2qoaHyD1OVOXVBcZRKaeDXm6In9d0srM";

  // !First fetch set page 1
  const firstFetch = async () => {
    setDownloading(true);
    await axios
      .get(
        `https://api.themoviedb.org/3/search/${type}?api_key=${api_key}&query=${search}&page=1&language=es-MX&include_adult=false`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        res.data.results.map((i: any) => {
          if (i.poster_path) i.poster_path = `${process.env.REACT_APP_TMDB_POSTER_220X300}/${i.poster_path}`;
          return i;
        });
        setState(res.data);
        setDownloading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // !Rerender on type change
  useEffect(() => {
    //     setQueries({ ...queries, type: params.type });
    search !== "" && firstFetch();
  }, [type, search]);

  // !Load more
  const loadMore = async () => {
    await axios
      .get(
        `https://api.themoviedb.org/3/search/${type}?api_key=${api_key}&query=${search}&page=${
          state.page + 1
        }&language=es-MX&include_adult=false`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        res.data.results.map((i: any) => {
          if (i.poster_path) i.poster_path = `${process.env.REACT_APP_TMDB_POSTER_220X300}/${i.poster_path}`;
          return i;
        });
        setState({ ...state, ...res.data, results: [...state.results, ...res.data.results] });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <ApiPageSt>
      <DashboardApi
        firstFetch={firstFetch}
        type={type}
        setType={setType}
        search={search}
        setSearch={setSearch}
        state={state}
      />
      <TableApi type={type} state={state} downloading={downloading} loadMore={loadMore} />
      <Outlet context={["title_admin"]} />
    </ApiPageSt>
  );
}
