import React from "react";
type option = { value: string; name: string };

interface props {
  label: string;
  name: string;
  value: string;
  onChange: any;
  options: option[];
  required: boolean;
}
export default function SelectForm(props: props) {
  return (
    <div className="form_container_label_input">
      <label className="label">{props.label}</label>
      <select
        className="input select"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        required={props.required}
      >
        {props.options.map((i: option, index) => (
          <option value={i.value} key={index}>
            {i.name}
          </option>
        ))}
      </select>
    </div>
  );
}
