import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { useEffect } from "react";
import TableApiEpisodes from "components_admin/molecules/tables/TableApiEpisodes";
const ApiEpisodesPageSt = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 100%;
`;
export default function ApiEpisodes() {
  const params = useParams();
  const [seasonsDocs, setSeasonsDocs] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const api_key = "eafee52978a6fa33af7118d81011116b";
  const token =
    "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJlYWZlZTUyOTc4YTZmYTMzYWY3MTE4ZDgxMDExMTE2YiIsInN1YiI6IjYzMjY4MzdiNjdiNjEzMDA3Yjg5MzA1MSIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.DkYIhC6xJOI2qoaHyD1OVOXVBcZRKaeDXm6In9d0srM";

  // !First fetch set page 1
  const fetchEpisodes = async () => {
    //     setDownloading(true);
    await axios
      .get(
        `https://api.themoviedb.org/3/tv/${params.tmdb_id}/season/${params.season_number}?api_key=${api_key}&language=es-MX`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        // !Setting poster
        res.data.episodes.map((i: any) => {
          if (i.still_path) i.still_path = `${process.env.REACT_APP_TMDB_THUMBNAIL_227X127}/${i.still_path}`;
          return i;
        });
        setSeasonsDocs(res.data.episodes);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // !Rerender on type change
  useEffect(() => {
    fetchEpisodes();
  }, []);

  return (
    <ApiEpisodesPageSt>
      {/* <DashboardApiEpisodes /> */}
      <TableApiEpisodes seasonsDocs={seasonsDocs} downloading={downloading} />
      <Outlet context={["episode"]} />
    </ApiEpisodesPageSt>
  );
}
