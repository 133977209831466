import React from "react";

interface props {
  label: string;
  type: string;
  name: string;
  value: string;
  onChange: any;
}
export default function InputDateForm(props: props) {
  return (
    <div className="form_container_label_input">
      <label className="label">{props.label}</label>
      <input
        onWheel={(e) => e.currentTarget.blur()}
        className="input date"
        type={props.type}
        name={props.name}
        value={new Date(props.value).toISOString().split(".")[0]}
        onChange={props.onChange}
      />
    </div>
  );
}
